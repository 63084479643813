<ng-container *ngIf="(currentConversation$ | async) && formQuestions?.length > 0; else noQuestions">
  <form [formGroup]="form" class="pb-3 pb-xl-0" autocomplete="off">
    <div *ngFor="let q of formQuestions" class="mb-3">
      <label for="{{ q.id }}" class="question-title">
        {{ q.title }}
      </label>

      <div [ngSwitch]="q.controlType">
        <input
          *ngSwitchCase="'text'"
          type="text"
          [@saveQuestion]="qStates[q.id]"
          [formControlName]="q.id"
          class="form-control"
          [class.has-value]="form.get(q.id).value"
        />

        <input
          *ngSwitchCase="'number'"
          type="number"
          pattern="-?[0-9]{1,}(\.[0-9]{1,})?"
          inputmode="numeric"
          [@saveQuestion]="qStates[q.id]"
          [formControlName]="q.id"
          class="form-control"
          [class.has-value]="form.get(q.id).value"
        />

        <select
          *ngSwitchCase="'select'"
          [@saveQuestion]="qStates[q.id]"
          [formControlName]="q.id"
          class="form-control"
          [class.has-value]="form.get(q.id).value"
        >
          <option [value]="''" class="placeholder">
            {{ 'common.selectOne' | translate }}
          </option>
          <option *ngFor="let o of q.responses" [value]="o.text" class=" selection-text">{{ o.text }} </option>
        </select>

        <div [ngSwitch]="q.controlType">
          <small *ngSwitchCase="'text'" class="sub-text m-1">(Text Input) </small>
          <small *ngSwitchCase="'number'" class="sub-text ml-1">
            (Numerical Input)
          </small>
        </div>

        <p *ngIf="qErrors[q.id]" class="text-danger">{{ qErrors[q.id] }}</p>
      </div>
    </div>
  </form>
</ng-container>

<ng-template #noQuestions>
  <div class="text-center mt-3 sub-text">
    {{ 'convQuestions.noQuestions' | translate }}
  </div>
</ng-template>
