<div class="d-flex flex-column h-100">
  <div class="container my-auto">
    <div class="d-flex flex-column align-items-center">
      <h1 class="text-center mt-4">
        {{ 'break.breakTitle' | translate }}
      </h1>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width="1041.32"
        height="577.51"
        viewBox="0 0 1041.32 577.51"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <path
          d="M1040.82,622.79q-1.74,3.75-3.47,7.4-2.7,5.67-5.33,11.12c-.78,1.61-1.56,3.19-2.32,4.77-8.6,17.57-16.63,33.11-23.45,45.89a73.21,73.21,0,0,1-63.81,38.73l-151.65,1.65h-1.6l-13,.14-11.12.12L731,733l-1.38,0-17.36.19h-.53l-107,1.16-95.51,1-11.11.12-69,.75H429l-44.75.48h-.48l-141.5,1.53-42.33.46a88,88,0,0,1-10.79-.54h0c-1.22-.14-2.44-.3-3.65-.49a87.38,87.38,0,0,1-51.29-27.54C116,690,102.75,666.7,93.85,641.31q-1.93-5.49-3.6-11.12C59.44,526,97,391.64,164.6,301.75q4.25-5.64,8.64-11l.07-.08c20.79-25.52,44.1-46.84,68.93-62,44-26.91,92.75-34.49,140.7-11.9,40.57,19.12,78.45,28.11,115.17,30.55,3.71.24,7.42.42,11.11.53,84.23,2.65,163.17-27.7,255.87-47.29,3.69-.78,7.39-1.55,11.12-2.28,66.13-13.16,139.49-20.1,226.73-5.51a189.09,189.09,0,0,1,26.76,6.4q5.77,1.86,11.12,4c41.64,16.94,64.35,48.24,74,87.46q1.37,5.46,2.37,11.11C1134.3,396.08,1084.19,529.9,1040.82,622.79Z"
          transform="translate(-79.34 -161.25)"
          fill="#f2f2f2"
        />
        <path
          d="M576.36,630.19a95.22,95.22,0,0,1-1.87,11.12h93.7V630.19ZM498.11,693l11.11-.09V665.44c-3.81-.17-7.52-.34-11.11-.52ZM265.19,630.19v11.12h198.5V630.19Zm849.68-339.55h-74V203.18q-5.35-2.18-11.12-4v91.5H776.21V198.25c-3.73.73-7.43,1.5-11.12,2.28v90.11H509.22V247.82c-3.69-.11-7.4-.29-11.11-.53v43.35H242.24v-62c-24.83,15.16-48.14,36.48-68.93,62h-.07v.08q-4.4,5.4-8.64,11h8.64V630.19h-83q1.66,5.63,3.6,11.12h79.39v93.62a87,87,0,0,0,12.2,2.79c1.21.19,2.43.35,3.65.49h0a88,88,0,0,0,10.79.54l42.33-.46v-97H498.11v94.21l11.11-.12V641.31H765.09v91.31l11.12-.12V641.31H1029.7v4.77c.76-1.58,1.54-3.16,2.32-4.77q2.63-5.44,5.33-11.12,1.73-3.65,3.47-7.4v-321h76.42Q1116.23,296.1,1114.87,290.64ZM242.24,630.19V301.75H498.11V630.19Zm267,0V301.75H765.09V630.19Zm520.48,0H776.21V301.75H1029.7Z"
          transform="translate(-79.34 -161.25)"
          opacity="0.1"
        />
        <path
          d="M914.07,514.33a186.94,186.94,0,0,0-57.35-64.25,12.69,12.69,0,0,0-5.73-2.16c2.81-18.48,2.22-38.9-8.21-54.3-24.75-36.54-150.85,1.17-150.85,1.17-88.39-30.64-165,8.25-165,8.25s-97.82-15.32-142.6-3.53c-21.12,5.56-26.25,27.1-25.77,48.32h-.07a12.45,12.45,0,0,0-9.75,4.68c-11.91,14.88-47.89,60.71-46.91,69.56,1.18,10.61,40.07,148.49,38.31,215.08l543.29-6s9-134,31.51-207.35A12.44,12.44,0,0,0,914.07,514.33Z"
          transform="translate(-79.34 -161.25)"
          fill="#3f3d56"
        />
        <polygon points="267.88 361.42 290.27 316.63 309.12 403.84 309.34 444.32 267.88 361.42" opacity="0.1" />
        <polygon points="781.7 361.42 759.31 316.63 733.34 399.32 738.34 446.32 781.7 361.42" opacity="0.1" />
        <polygon points="307.48 401.46 733.38 399.13 738.1 446.27 309.12 445.09 307.48 401.46" fill="#3f3d56" />
        <polygon points="307.48 401.46 733.38 399.13 738.1 446.27 309.12 445.09 307.48 401.46" opacity="0.1" />
        <rect x="307.34" y="441.68" width="433" height="50.68" rx="10.47" fill="#fff" opacity="0.1" />
        <path
          d="M731.07,433.39s-1.44-2-3.14-.92c-.81.53-4.62,5.33-8.36,10.21-1.84,2.39-3.67,4.8-5.12,6.72-1.79,2.36-3,4-3,4l-.72,1.35-4.37,8.17-4.11,7.68-1,3.62,13.88,4.72,15.8-4.31-.68-2.93-.12-.5.13-6.74.36-19,.17-8.55,0-1Z"
          transform="translate(-79.34 -161.25)"
          fill="#ffe09c"
        />
        <path
          d="M699.29,480.23s15,5.35,22.69,2.55c0,0,6.65-2.12,9.27-4.17,0,0,2.79-1.46-1-6.22,0,0-9.71,5.95-18.9,3.61l-9.19-2.35Z"
          transform="translate(-79.34 -161.25)"
          fill="#e7c4c3"
        />
        <ellipse
          cx="713.92"
          cy="469.92"
          rx="4.91"
          ry="4.16"
          transform="translate(-218.96 264.13) rotate(-30.37)"
          fill="#ff7f8e"
        />
        <ellipse
          cx="723.89"
          cy="457.96"
          rx="4.91"
          ry="4.16"
          transform="translate(-211.54 267.53) rotate(-30.37)"
          fill="#ff7f8e"
        />
        <path
          d="M730.25,464.46a5.14,5.14,0,0,0-4.68.37c-2.34,1.37-3.3,4.09-2.13,6.07s4,2.48,6.33,1.11c.16-.1.32-.2.47-.31l-.12-.5Z"
          transform="translate(-79.34 -161.25)"
          fill="#ff7f8e"
        />
        <path
          d="M713.28,456.53a3.83,3.83,0,0,0-2.56-1.78l-4.37,8.17a5.11,5.11,0,0,0,4.8-.32C713.49,461.23,714.44,458.51,713.28,456.53Z"
          transform="translate(-79.34 -161.25)"
          fill="#ff7f8e"
        />
        <path
          d="M723,444.56a4,4,0,0,0-3.41-1.88c-1.84,2.39-3.67,4.8-5.12,6.72l.06.12c1.16,2,4,2.47,6.34,1.1S724.14,446.54,723,444.56Z"
          transform="translate(-79.34 -161.25)"
          fill="#ff7f8e"
        />
        <path
          d="M730.78,436.9a5.62,5.62,0,0,0-1.47.6c-2.34,1.37-3.29,4.09-2.13,6.07a4,4,0,0,0,3.43,1.88Z"
          transform="translate(-79.34 -161.25)"
          fill="#ff7f8e"
        />
        <path
          d="M707.49,469.69l-.8-1.35a8.61,8.61,0,0,0,3.24-1.8c.06-.06.11-.13.16-.19,1-1.26.79,1.36.79,1.36S708.42,469.8,707.49,469.69Z"
          transform="translate(-79.34 -161.25)"
          fill="#94cc79"
        />
        <path
          d="M719.29,467.6l-.8-1.36a8.68,8.68,0,0,0,3.24-1.8c0-.06.11-.12.16-.19,1-1.25.79,1.36.79,1.36S720.22,467.71,719.29,467.6Z"
          transform="translate(-79.34 -161.25)"
          fill="#94cc79"
        />
        <path
          d="M718.21,462l-.8-1.36a8.68,8.68,0,0,0,3.24-1.79c.05-.07.11-.13.16-.19,1-1.26.79,1.35.79,1.35S719.14,462.09,718.21,462Z"
          transform="translate(-79.34 -161.25)"
          fill="#94cc79"
        />
        <path
          d="M719.64,451.9l-.8-1.36a8.68,8.68,0,0,0,3.24-1.8c0-.06.11-.12.16-.19,1-1.26.79,1.36.79,1.36S720.57,452,719.64,451.9Z"
          transform="translate(-79.34 -161.25)"
          fill="#94cc79"
        />
        <path
          d="M726.41,439.68l-.79-1.36a8.6,8.6,0,0,0,3.23-1.8,1.46,1.46,0,0,0,.16-.19c1-1.25.8,1.36.8,1.36S727.34,439.78,726.41,439.68Z"
          transform="translate(-79.34 -161.25)"
          fill="#94cc79"
        />
        <path
          d="M725.65,452.15l-.8-1.36a8.6,8.6,0,0,0,3.24-1.79c0-.07.11-.13.16-.19,1-1.26.79,1.35.79,1.35S726.58,452.26,725.65,452.15Z"
          transform="translate(-79.34 -161.25)"
          fill="#94cc79"
        />
        <path
          d="M696.83,476s-1.92,5,2.46,4.2a17.34,17.34,0,0,0,3.87-7.91s9.47-20.75,12.27-22.65c0,0,12.34-16.87,13.67-17.12,0,0,.43-1.64-3.88,1.83s-13.75,16.83-13.75,16.83l-9,14.67-2.12,5.85Z"
          transform="translate(-79.34 -161.25)"
          fill="#e7c4c3"
        />
        <path
          d="M696.83,476s-1.92,5,2.46,4.2a17.34,17.34,0,0,0,3.87-7.91s9.47-20.75,12.27-22.65c0,0,12.34-16.87,13.67-17.12,0,0,.43-1.64-3.88,1.83s-13.75,16.83-13.75,16.83l-9,14.67-2.12,5.85Z"
          transform="translate(-79.34 -161.25)"
          fill="#fff"
          opacity="0.2"
        />
        <path
          d="M548.08,577.57a120.28,120.28,0,0,0,6.08,15.86,147.27,147.27,0,0,0,20.27-5.78c.21-.07-3.79-5.3-4.21-5.73a20.12,20.12,0,0,0-5.73-4.4c-2.11-.92-4.71-.22-7-.1Q552.81,577.66,548.08,577.57Z"
          transform="translate(-79.34 -161.25)"
          fill="#fbbebe"
        />
        <path
          d="M564.53,585.78c-3.44-1.08-5.54-4.55-6.94-7.89s-2.53-7-5.19-9.44a3.09,3.09,0,0,0-2.34,1.76,29.32,29.32,0,0,1-1.39,2.74c-1.1,1.6-3,2.38-4.45,3.74s-2.26,3.43-3.77,4.8c-1.9,1.72-4.58,2.15-7.07,2.72a45.16,45.16,0,0,0-11.08,4.1,18.59,18.59,0,0,1-4.1,1.84,15.14,15.14,0,0,1-4.8.16l-11-.78a8.78,8.78,0,0,0-2.94.12,5.36,5.36,0,0,0-3.5,5.53,8.6,8.6,0,0,0,3.48,5.93,23.47,23.47,0,0,0,6.28,3.17,185,185,0,0,0,29.54,8.44v2.18a50.85,50.85,0,0,0,22.49,5.24,3.56,3.56,0,0,0,1.68-.29,3.64,3.64,0,0,0,1.42-2.16,87.26,87.26,0,0,0,4.59-24,18.24,18.24,0,0,0-.47-6.17c-.6-2,1.46-1.15-.45-1.93"
          transform="translate(-79.34 -161.25)"
          fill="#2f2e41"
        />
        <path
          d="M775.85,544.24c-32.67,2-65-7.61-97.66-8.63a48,48,0,0,0-12.15.78c-4.2.94-8.12,2.84-12,4.66-17.55,8.16-35.69,14.92-53.82,21.67l-20.82,7.76c-4.16,1.55-8.47,3.12-12.9,2.85-2-.12-4.43-.42-5.54,1.23-3.39,5-1.79,12.39.45,18,.72,1.82,2,3.8,3.9,4,1.37.14,2.65-.65,4-1,3.79-1,7.57,1.54,11.47,1.95,4.82.51,9.29-2.23,13.84-3.91,7.54-2.79,15.79-2.7,23.72-4,13.3-2.22,25.62-8.39,37.14-15.42,4.38-2.68,8.82-5.55,13.86-6.53,5.79-1.13,11.74.36,17.43,2a401.22,401.22,0,0,1,39.94,13.6c6.88,2.76,13.72,5.71,20.88,7.62a75.53,75.53,0,0,0,46.78-2.73c5.22-2.07,10.91-5.72,11.21-11.36.17-3.22-2.33-6-4-8.8-1.06-1.77-2.13-3.53-3.3-5.23C792.61,554.49,785.77,543.65,775.85,544.24Z"
          transform="translate(-79.34 -161.25)"
          fill="#2f2e41"
        />
        <path
          d="M775.85,544.24c-32.67,2-65-7.61-97.66-8.63a48,48,0,0,0-12.15.78c-4.2.94-8.12,2.84-12,4.66-17.55,8.16-35.69,14.92-53.82,21.67l-20.82,7.76c-4.16,1.55-8.47,3.12-12.9,2.85-2-.12-4.43-.42-5.54,1.23-3.39,5-1.79,12.39.45,18,.72,1.82,2,3.8,3.9,4,1.37.14,2.65-.65,4-1,3.79-1,7.57,1.54,11.47,1.95,4.82.51,9.29-2.23,13.84-3.91,7.54-2.79,15.79-2.7,23.72-4,13.3-2.22,25.62-8.39,37.14-15.42,4.38-2.68,8.82-5.55,13.86-6.53,5.79-1.13,11.74.36,17.43,2a401.22,401.22,0,0,1,39.94,13.6c6.88,2.76,13.72,5.71,20.88,7.62a75.53,75.53,0,0,0,46.78-2.73c5.22-2.07,10.91-5.72,11.21-11.36.17-3.22-2.33-6-4-8.8-1.06-1.77-2.13-3.53-3.3-5.23C792.61,554.49,785.77,543.65,775.85,544.24Z"
          transform="translate(-79.34 -161.25)"
          opacity="0.1"
        />
        <path
          d="M610.8,579.16q7.66,2.61,15.48,4.81c2.91-4.78,8.36-8.11,9.6-13.57a.93.93,0,0,0-.59-1.26,28.83,28.83,0,0,0-12.11-3.55c-3.36-.21-4.89,3.29-6.42,5.76C616.15,572.34,612.17,579.63,610.8,579.16Z"
          transform="translate(-79.34 -161.25)"
          fill="#fbbebe"
        />
        <path
          d="M618,575.67a11.19,11.19,0,0,1-7.52-4.94c-.72-1.26-1.49-3-2.93-2.87s-2,1.69-2.26,3a85.2,85.2,0,0,1-4.21,14.53,27.27,27.27,0,0,1-9,11.88c-1.9,1.31-4,2.3-5.86,3.68-1.67,1.23-3.12,2.79-5,3.74a26.67,26.67,0,0,1-3.8,1.42,26.57,26.57,0,0,0-5.44,2.65,10.82,10.82,0,0,0-3.7,3.54,5.17,5.17,0,0,0-.31,5,5.36,5.36,0,0,0,3.63,2.48,15.47,15.47,0,0,0,4.51.12c5.95-.44,11.9-1,17.85-1.5a52.18,52.18,0,0,0,7.48-1c1.31-.32,2.58-.73,3.88-1.07a32.64,32.64,0,0,1,6.8-1,6,6,0,0,1,2,.18c1.38.45,2.35,1.82,3.78,2.1a6,6,0,0,0,2.69-.36c4.28-1.17,8.79-1.14,13.22-1.38,2-.1,4.24-.47,5.14-2.22,1.12-2.18-.74-4.62-1.47-7a21.14,21.14,0,0,1-.62-4.57,27.81,27.81,0,0,0-3.28-10.85c-1.51-2.78-3.58-5.86-2.52-8.85.36-1,1.08-2,1-3C631.72,574.59,620.71,576.24,618,575.67Z"
          transform="translate(-79.34 -161.25)"
          fill="#2f2e41"
        />
        <path
          d="M726.86,491.83a25.57,25.57,0,0,1-13-12.93c-.55-1.24-1-2.53-1.52-3.78a28.86,28.86,0,0,0-7.78-10.81,3.76,3.76,0,0,0-2.89-1.22,3.1,3.1,0,0,0-2.12,2.28,9.06,9.06,0,0,0-.22,3.49,62.43,62.43,0,0,0,1.42,8.16l1.58,7.21a13.25,13.25,0,0,0,1,3.22,11.22,11.22,0,0,0,2.65,3.18c1.45,1.34,2.92,2.63,4.41,3.89a12.37,12.37,0,0,0,7.65,3.4c6.48,1.2,13.08,2.94,18.56,7.17-.07,0,0-4.84-.12-5.39-.25-1.45-.45-2.89-1.39-3.87C732.94,493.63,729.45,493,726.86,491.83Z"
          transform="translate(-79.34 -161.25)"
          fill="#fbbebe"
        />
        <path
          d="M726.86,491.83a25.57,25.57,0,0,1-13-12.93c-.55-1.24-1-2.53-1.52-3.78a28.86,28.86,0,0,0-7.78-10.81,3.76,3.76,0,0,0-2.89-1.22,3.1,3.1,0,0,0-2.12,2.28,9.06,9.06,0,0,0-.22,3.49,62.43,62.43,0,0,0,1.42,8.16l1.58,7.21a13.25,13.25,0,0,0,1,3.22,11.22,11.22,0,0,0,2.65,3.18c1.45,1.34,2.92,2.63,4.41,3.89a12.37,12.37,0,0,0,7.65,3.4c6.48,1.2,13.08,2.94,18.56,7.17-.07,0,0-4.84-.12-5.39-.25-1.45-.45-2.89-1.39-3.87C732.94,493.63,729.45,493,726.86,491.83Z"
          transform="translate(-79.34 -161.25)"
          opacity="0.1"
        />
        <ellipse cx="714.05" cy="267.38" rx="7.95" ry="7.99" fill="#2f2e41" />
        <path
          d="M788.47,423.8c-2.15-.24-4.55-.41-6.28.91a8.5,8.5,0,0,0-2.15,2.92,42.24,42.24,0,0,0-4.13,9.73c-.71,2.92-.83,6-1.71,8.84-1.63,5.32-5.8,9.81-6.28,15.36-.21,2.48.21,5.39-1.61,7.09a10.14,10.14,0,0,1-1.42,1,10.8,10.8,0,0,0-3.13,3.22,10.42,10.42,0,0,1-2.22,3.07c-1.62,1.2-4.3,1.07-5.1,2.93s1.26,4,.78,6c-.39,1.64-2.22,2.39-3.48,3.51-1.62,1.43-2.36,3.58-3,5.64a18.74,18.74,0,0,1-15.2-3.79c2.21,5.46,2.26,11.49,2.36,17.39,0,1.91,0,4.06-1.4,5.39,6.33.14,12.5,1.85,18.67,3.25,2.7.61,5.63,1.14,8.14,0a13.11,13.11,0,0,0,3.73-3c3.36-3.41,6.88-7.12,7.7-11.85.46-2.64,0-5.36.14-8a45.7,45.7,0,0,1,2-9.76,115.57,115.57,0,0,0,3.57-20.08c.36-4.18,4-7.55,5.88-11.3,3.6-7.06,7.27-14.42,7.54-22.35C792,427.3,791.1,424.09,788.47,423.8Z"
          transform="translate(-79.34 -161.25)"
          fill="#2f2e41"
        />
        <path
          d="M788.47,423.8c-2.15-.24-4.55-.41-6.28.91a8.5,8.5,0,0,0-2.15,2.92,42.24,42.24,0,0,0-4.13,9.73c-.71,2.92-.83,6-1.71,8.84-1.63,5.32-5.8,9.81-6.28,15.36-.21,2.48.21,5.39-1.61,7.09a10.14,10.14,0,0,1-1.42,1,10.8,10.8,0,0,0-3.13,3.22,10.42,10.42,0,0,1-2.22,3.07c-1.62,1.2-4.3,1.07-5.1,2.93s1.26,4,.78,6c-.39,1.64-2.22,2.39-3.48,3.51-1.62,1.43-2.36,3.58-3,5.64a18.74,18.74,0,0,1-15.2-3.79c2.21,5.46,2.26,11.49,2.36,17.39,0,1.91,0,4.06-1.4,5.39,6.33.14,12.5,1.85,18.67,3.25,2.7.61,5.63,1.14,8.14,0a13.11,13.11,0,0,0,3.73-3c3.36-3.41,6.88-7.12,7.7-11.85.46-2.64,0-5.36.14-8a45.7,45.7,0,0,1,2-9.76,115.57,115.57,0,0,0,3.57-20.08c.36-4.18,4-7.55,5.88-11.3,3.6-7.06,7.27-14.42,7.54-22.35C792,427.3,791.1,424.09,788.47,423.8Z"
          transform="translate(-79.34 -161.25)"
          opacity="0.1"
        />
        <path
          d="M781,551.12c-6.8-3.24-11.78-9.27-17.28-14.44-10.5-9.86-23.35-16.84-34.39-26.09-7.32-6.12-13.86-13.26-21.9-18.39A54.54,54.54,0,0,0,684,484a6.45,6.45,0,0,0-2.87.14,6.82,6.82,0,0,0-2.67,2.11,83.93,83.93,0,0,0-9.46,13C655,523,635.12,543,618.65,565.07a3,3,0,0,0-.2,4l3.35,6.56c1.87,3.68,4.88,8,8.92,7.23,1.85-.34,3.32-1.7,4.68-3l15-14.37,15.77-15.08c9.24-8.83,18.63-17.87,24.66-29.16,4.7,8.16,13.06,13.5,18.79,21,2.18,2.84,4,6,5.9,9,3.12,4.82,6.67,9.35,10.21,13.87l18.09,23.09c2.46,3.14,5,6.36,8.37,8.49,4.29,2.71,9.5,3.43,14.54,3.88a193.65,193.65,0,0,0,20.44.74c5.2-.09,10.56-.43,15.23-2.74s8.5-7,8.26-12.28a18.21,18.21,0,0,0-2.61-7.65C801.63,566.75,793.18,556.9,781,551.12Z"
          transform="translate(-79.34 -161.25)"
          fill="#2f2e41"
        />
        <ellipse cx="718.94" cy="272.91" rx="6.11" ry="6.15" fill="#e8e8f0" />
        <path
          d="M796.93,429.54a11.65,11.65,0,0,1,.67,8.82,34.34,34.34,0,0,1-3.93,8.14,40.58,40.58,0,0,1-5.11,7.29c9.43.46,18.58-3.24,26.9-7.73,6.7-3.6,13.38-8.06,17-14.8a7.8,7.8,0,0,1-6.25-1.15,23.22,23.22,0,0,1-4.78-4.46l-4.74-5.33a21.08,21.08,0,0,1-2.91-3.82,10.71,10.71,0,0,1-1.25-6.18,35.09,35.09,0,0,1-6.27,5.2c-3.38,2.2-7.11,3.8-10.5,6-1.2.77-3.88,2-3.19,3.59S796,427.86,796.93,429.54Z"
          transform="translate(-79.34 -161.25)"
          fill="#fbbebe"
        />
        <path
          d="M796.93,429.54a11.65,11.65,0,0,1,.67,8.82,34.34,34.34,0,0,1-3.93,8.14,40.58,40.58,0,0,1-5.11,7.29c9.43.46,18.58-3.24,26.9-7.73,6.7-3.6,13.38-8.06,17-14.8a7.8,7.8,0,0,1-6.25-1.15,23.22,23.22,0,0,1-4.78-4.46l-4.74-5.33a21.08,21.08,0,0,1-2.91-3.82,10.71,10.71,0,0,1-1.25-6.18,35.09,35.09,0,0,1-6.27,5.2c-3.38,2.2-7.11,3.8-10.5,6-1.2.77-3.88,2-3.19,3.59S796,427.86,796.93,429.54Z"
          transform="translate(-79.34 -161.25)"
          opacity="0.1"
        />
        <path
          d="M797.06,430.47c-1.77,3-2.15,6.5,0,9.22.27.34,1.26,2.44,1.69,2.46a1.67,1.67,0,0,0,1.08-.55c2.29-2.09,4-4.76,6-7.13s4.55-4.52,7.61-5c3.69-.57,7.26,1.38,10.49,3.27a10.05,10.05,0,0,1,3.58,2.93,9.63,9.63,0,0,1,1.18,4c1.11,8.41,1.09,16.93,1.06,25.41,0,6.14,0,12.31-1.2,18.33-.6,3.12-1.5,6.17-1.93,9.31a62.84,62.84,0,0,0-.37,8.35v11.68a29.76,29.76,0,0,0,.45,6.4c.55,2.56,1.76,4.92,2.57,7.41a48.82,48.82,0,0,1,1.71,8.76l1.6,12.09c.5,3.81.87,8-1.43,11-1,1.38-2.41,2.49-3.46,3.87-4,5.14-2.69,12.49-4.34,18.78a23.51,23.51,0,0,1-7.66,11.75c-2.83,2.4-6.48,4.28-10.11,3.61-4.91-.91-7.86-6-9.2-10.87s-1.82-10.1-4.72-14.18c-2.37-3.34-6-5.44-9.36-7.82s-6.56-5.45-7.28-9.49c-.32-1.76-.12-3.57-.31-5.35a12.65,12.65,0,0,0-7.82-10.27c-2.51-1-5.61-1.33-7-3.63,3.66-2.06,6.08-5.87,7.36-9.88s1.54-8.27,1.79-12.47l.84-14.05c.37-6.28.54-12.5,3.31-18.14,1.23-2.53,2.77-4.89,4.06-7.38a83.26,83.26,0,0,0,4.35-10.83c2-5.76,4-11.52,5.8-17.35a34.58,34.58,0,0,1,3.18-8.08C792,434.19,794.22,431,797.06,430.47Z"
          transform="translate(-79.34 -161.25)"
          fill="#158cba"
        />
        <path
          d="M765.86,521.75l3.72.13.79-5.61a11.73,11.73,0,0,1,.78-3.28,20.63,20.63,0,0,1,2.14-3.13c3.66-5.17,3.39-12,4.3-18.31,1.21-8.38,4.71-16.22,8.17-23.93L793,451.53a17.6,17.6,0,0,0,2-8.88c.07-2.49.8-11.53.87-14,0-.92-2-.41-2.45-1.23-.9-1.79-2.14,3.06-3.83,4.12s-2.63,3-3.34,4.86c-1.56,4.05-2.51,8.31-4,12.4s-3.47,8.11-6.74,11c-1.48,1.3-3.18,2.32-4.58,3.71-4.49,4.44-4.93,11.45-5.09,17.78C765.76,485,765,521.75,765.86,521.75Z"
          transform="translate(-79.34 -161.25)"
          fill="#2f2e41"
        />
        <path
          d="M819.38,423.76c-3.61,1.42-5.59,5.34-6.6,9.1s-1.43,7.78-3.37,11.15c-4.54,7.89-16.1,10.19-19.29,18.72-1,2.79-1,5.85-1.29,8.82-.58,6.11-2.59,12-3.18,18.12a79.77,79.77,0,0,0,.33,14.24l4,48.19c.59,7,.57,14.43-1.21,21.19,4.09.79,8.35-.27,12.14-2s7.26-4.11,10.91-6.12c6.28-3.47,13.21-5.92,18.71-10.54a10.79,10.79,0,0,0,2.52-2.79c1.1-1.9,1.2-4.21,1.72-6.35a34.62,34.62,0,0,1,1.81-5,148.83,148.83,0,0,0,5.26-14.86,80.83,80.83,0,0,0,2.41-10.2c.44-2.74.68-5.52.92-8.29a49.78,49.78,0,0,1,2.56-12.74,102.24,102.24,0,0,0,5.91-30.52,15.67,15.67,0,0,0-.31-4.49,24.87,24.87,0,0,0-2.44-5.1c-1.68-3.16-2.69-6.64-4.27-9.86-3.58-7.25-9.91-12.76-16.53-17.37C826.87,424.81,823.07,422.32,819.38,423.76Z"
          transform="translate(-79.34 -161.25)"
          fill="#2f2e41"
        />
        <path
          d="M734.91,502.38a29.06,29.06,0,0,1-7.69-5.85c-1-1.18-1.84-2.51-2.87-3.67a18.84,18.84,0,0,0-9.68-5.52,47.34,47.34,0,0,0-11.21-1.09,20.57,20.57,0,0,0-7.46.9,7.49,7.49,0,0,0-4.94,5.31c-.55,3,1.41,5.91,3.76,7.84a18.14,18.14,0,0,0,7.52,3.65,61.63,61.63,0,0,0,6.79.79c13.38,1.4,25.32,8.68,37,15.31q15.45,8.75,31.49,16.34a63.78,63.78,0,0,0,.53-9.67c-.06-2.41-.18-6.09-2.46-7.51-2.65-1.65-7-2-10-3a161.11,161.11,0,0,1-20.15-8.15Q740.13,505.41,734.91,502.38Z"
          transform="translate(-79.34 -161.25)"
          fill="#fbbebe"
        />
        <ellipse
          cx="794.03"
          cy="403.38"
          rx="23.97"
          ry="23.84"
          transform="translate(122.66 907.5) rotate(-75.27)"
          fill="#fbbebe"
        />
        <path
          d="M826.2,450.94c-2.13,2.48-3.2,5.68-4.63,8.62-2.94,6.06-7.56,11.26-9.87,17.6-2.44,6.68-2.36,14.62-7.14,19.87-.83.91-1.83,1.79-2.07,3a16,16,0,0,1-.25,2.19c-.68,1.9-3.8,2-4.38,3.94-.17.57-.08,1.19-.29,1.75-.48,1.25-2.13,1.44-3.24,2.2-2.32,1.57-1.85,5.21-3.5,7.48a6.27,6.27,0,0,1-6,2.08,21.32,21.32,0,0,1-6.18-2.31,5.69,5.69,0,0,0-2.22-.69,4.67,4.67,0,0,0-2.57.87c-4.4,2.8-6.47,8.35-6.44,13.59s1.83,10.28,3.67,15.18a6.59,6.59,0,0,0,1.22,2.26c1.46,1.54,4,1.3,5.94.44s3.7-2.21,5.78-2.59c3.69-.67,7.3,1.87,11,1.66,2.74-.16,5.17-1.75,7.44-3.29,3.64-2.46,7.34-5,10-8.48,1.64-2.14,2.84-4.57,4.27-6.86a79.39,79.39,0,0,1,6-8l13.74-16.82a36.3,36.3,0,0,0,3.7-5.11,35.86,35.86,0,0,0,2.88-7.68c1.31-4.49,2.63-9,2.85-13.69a54,54,0,0,0-1-11.47,39.79,39.79,0,0,0-3-10.92C839.08,449.52,831.81,444.39,826.2,450.94Z"
          transform="translate(-79.34 -161.25)"
          fill="#2f2e41"
        />
        <path
          d="M867.76,440.24a26.83,26.83,0,0,0-5.12-23.9c3.65-15.57-4.3-31.16-18.3-36.93.5-14.6-8.75-28-23-31.76-14.81-3.89-30,3.93-36.39,17.83-9-4.67-18.52-4.17-21.66,1.32-3.28,5.74,1.65,14.77,11,20.16a29.33,29.33,0,0,0,9.53,3.53,31,31,0,0,0,17.25,18.26,31.78,31.78,0,0,0,16.4,29.15,26.56,26.56,0,0,0,4.43,11.43,20,20,0,0,0-.7,2.19c-2.94,11.15,3.42,22.49,14.19,25.32s21.89-3.91,24.82-15.06a21.36,21.36,0,0,0,.4-9.11A27.1,27.1,0,0,0,867.76,440.24Z"
          transform="translate(-79.34 -161.25)"
          fill="#3f3d56"
        />
        <path
          d="M769.46,369.7c3.14-5.49,12.65-6,21.66-1.32,6.37-13.9,21.58-21.73,36.39-17.83.67.17,1.33.38,2,.6a30.21,30.21,0,0,0-8.16-3.5c-14.81-3.89-30,3.93-36.39,17.83-9-4.67-18.52-4.17-21.66,1.32-3.28,5.74,1.65,14.77,11,20.16a31.31,31.31,0,0,0,5.52,2.5C770.88,384,766.26,375.3,769.46,369.7Z"
          transform="translate(-79.34 -161.25)"
          opacity="0.1"
        />
        <path
          d="M827.38,454.42a20,20,0,0,1,.7-2.19,26.6,26.6,0,0,1-4.43-11.44,31.76,31.76,0,0,1-16.4-29.14A31,31,0,0,1,790,393.39a27.58,27.58,0,0,1-5.71-1.67,30.89,30.89,0,0,0,16.77,17,31.78,31.78,0,0,0,16.4,29.15,26.56,26.56,0,0,0,4.43,11.43,20,20,0,0,0-.7,2.19c-2.93,11.11,3.38,22.41,14.09,25.29A20.84,20.84,0,0,1,827.38,454.42Z"
          transform="translate(-79.34 -161.25)"
          opacity="0.1"
        />
        <ellipse
          cx="801.34"
          cy="407.73"
          rx="3.67"
          ry="4.91"
          transform="translate(-162.76 406.5) rotate(-37.22)"
          fill="#fbbebe"
        />
        <circle cx="431.8" cy="23.05" r="16.29" fill="#3f3d56" />
        <ellipse cx="467.15" cy="59.2" rx="45.69" ry="59.2" fill="#3f3d56" />
        <path
          d="M546.49,261.77c-22.89,0-41.85-21.81-45.18-50.26a77.1,77.1,0,0,0-.52,8.94c0,32.69,20.46,59.2,45.7,59.2s45.69-26.51,45.69-59.2a77.1,77.1,0,0,0-.52-8.94C588.34,240,569.38,261.77,546.49,261.77Z"
          transform="translate(-79.34 -161.25)"
          opacity="0.1"
        />
        <path
          d="M643.85,393.83c-1,4-5.21,7.26-9.89,9.51-9.86,4.75-22,6.16-33.79,6.64a56.68,56.68,0,0,1-9-.17c-6.17-.73-11.89-3.36-15.46-7.11a2.85,2.85,0,0,1-1-1.62,2.88,2.88,0,0,1,1-2C582,392.22,592.48,388,603,385c8-2.31,21.94-8.29,30.67-7.48C642.15,378.25,645.09,388.73,643.85,393.83Z"
          transform="translate(-79.34 -161.25)"
          fill="#3f3d56"
        />
        <path
          d="M643.85,393.83c-1,4-5.21,7.26-9.89,9.51-9.86,4.75-22,6.16-33.79,6.64a56.68,56.68,0,0,1-9-.17c-6.17-.73-11.89-3.36-15.46-7.11a2.85,2.85,0,0,1-1-1.62,2.88,2.88,0,0,1,1-2C582,392.22,592.48,388,603,385c8-2.31,21.94-8.29,30.67-7.48C642.15,378.25,645.09,388.73,643.85,393.83Z"
          transform="translate(-79.34 -161.25)"
          opacity="0.1"
        />
        <path
          d="M518.59,363.69a24.94,24.94,0,0,0-1.51,19,33.23,33.23,0,0,0,11.15,15.78C541,408.72,558.52,410.13,574.85,410a360.64,360.64,0,0,0,42.82-2.94c4.73-.61,9.51-1.33,13.89-3.22,5.33-2.31,10-6.54,11.91-12s.56-12.22-4-15.88c-4-3.22-9.43-3.66-14.52-4l-29.15-1.75c2.88-2.92,6.71-4.57,10.22-6.68,4.65-2.82,7.74-7.54,11.13-11.79,5-6.36,11-11.92,17-17.45,1,5.44,4.39,9.91,8.44,13.67s8.72,6.78,12.94,10.35c10.2,8.66,17.54,20.42,28.27,28.42a55.4,55.4,0,0,0,20.27-10.12l-4.17-7.55a5,5,0,0,0-1.57-2c-1.19-.72-2.71-.35-4.08-.55-3.66-.55-5.26-4.78-6.7-8.19-3.94-9.38-11.5-16.65-18-24.51A122.82,122.82,0,0,1,657,315.24c-2.17-3.91-4.15-8-7.1-11.37s-7.06-6-11.54-6c-4,0-7.6,2-11,4a291.53,291.53,0,0,0-29.84,20.33c-5,3.86-9.93,7.95-15.77,10.32-2.46,1-5,1.68-7.41,2.89-4.13,2.13-7.27,5.73-10.65,8.92C551.65,355.72,535.16,363.1,518.59,363.69Z"
          transform="translate(-79.34 -161.25)"
          fill="#605d82"
        />
        <path
          d="M579.55,282.59a29.77,29.77,0,0,1-2.19,10.15,12.65,12.65,0,0,1-7.28,7,15.61,15.61,0,0,1-7.1.23,13.72,13.72,0,0,1-5.79-2c-2.73-1.88-4.09-5.23-4.59-8.51s-.27-6.63-.59-9.93a54.53,54.53,0,0,0-1.52-7.9c-.21-.86-.43-1.72-.65-2.58s-.41-1.72-.6-2.58-.34-1.61-.48-2.43-.22-1.38-.29-2.07a23.39,23.39,0,0,0-1-6.08,18.24,18.24,0,0,0-2.13-3.6c-.36-.52-.71-1-1-1.57a7.44,7.44,0,0,1-1.23-4.73,4,4,0,0,1,.19-.76,2.33,2.33,0,0,1,.11-.28,2.28,2.28,0,0,0,1.55-1.45,4.33,4.33,0,0,0-.19-2.25,14,14,0,0,0-1.21-2.89c-.33-.6-1.46-1.69-1.47-2.38,0-1.39,2.7-2.51,3.8-3.06a21.15,21.15,0,0,1,10.86-2.2c2.4.16,5,.9,6.33,2.92,1.2,1.81,1,4.16,1.55,6.27a1.74,1.74,0,0,0,.37.84,1.7,1.7,0,0,0,.94.43,5.6,5.6,0,0,0,1.78.07h0a2.54,2.54,0,0,0,.47-.07,6.3,6.3,0,0,0,2.56-1.1c.5-.14,1-.24,1.49-.33h.06c2,6.28,2.25,13,3.88,19.42.81,3.18,2,6.32,2.13,9.6,0,.14,0,.29,0,.43,0,1.06-.06,2.12,0,3.17a9.38,9.38,0,0,0,.16,1.4,21.36,21.36,0,0,0,.66,2.2l0,0c.07.23.14.46.2.7a4.7,4.7,0,0,1,.12.51h0A18.87,18.87,0,0,1,579.55,282.59Z"
          transform="translate(-79.34 -161.25)"
          fill="#fbbebe"
        />
        <circle cx="472.33" cy="56.82" r="23.44" fill="#fbbebe" />
        <path
          d="M700.5,345.79A38.47,38.47,0,0,0,693.89,328l1.83,10.85a4.07,4.07,0,0,1-4.25-1.31,13,13,0,0,1-2.23-4.14,85,85,0,0,0-10.37-18.6c-14.92-1.25-29.68-7.43-42.8-14.65s-25.41-15.85-38.37-23.36a56.27,56.27,0,0,0-7-3.58c-4.05-1.62-8.6-2.46-12.44-4.37l-.77-.41a15.48,15.48,0,0,1-2.46-1.64,13.36,13.36,0,0,1-1.17-1.09,25.74,25.74,0,0,1-4.5-7.51c-2.06-4.56-2.8-10.07-4.85-14.63a14.49,14.49,0,0,1,3.15-2.23h0l.5-.25a15.31,15.31,0,0,1,2.53-.92c.5-.14,1-.24,1.49-.33h.06a14.71,14.71,0,0,1,6.94.55c3.26,1.11,6,3.31,8.76,5.4,4.35,3.29,8.87,6.39,12.82,10.16,2.26,2.17,4.33,4.55,6.76,6.52a53.62,53.62,0,0,0,6.39,4.24l39.54,23.55c4.46,2.65,8.93,5.31,13.29,8.13,6.63,4.27,13,8.9,19.4,13.53l11.29,8.18a12.38,12.38,0,0,1,3.2,3,10.74,10.74,0,0,1,1.41,4.33A39.62,39.62,0,0,1,700.5,345.79Z"
          transform="translate(-79.34 -161.25)"
          fill="#fbbebe"
        />
        <path
          d="M585.37,300.22c-1.59,7.09-8.75,11.38-12.5,17.61-4.66,7.73-2.76,16.72-1,25.58l-9.59,8.26c-1.76,1.51-3.53,3-5.42,4.37a72.89,72.89,0,0,1-8.67,5l-8.48,4.36a40.66,40.66,0,0,1-8.31,3.52c-5.58,1.43-11.5.43-17-1.22a7.58,7.58,0,0,1-1.86-.76,3.05,3.05,0,0,1-.88-.8,4.89,4.89,0,0,1-.56-3.21c.56-9.28,2.83-18.37,5.09-27.39A106.67,106.67,0,0,1,521,319.64a107.16,107.16,0,0,1,6.83-12.82c3-5.07,6-10.18,10-14.53,1.71-1.88,3.62-3.66,4.64-6,1.41-3.22.86-6.91,1.15-10.41a21.59,21.59,0,0,1,2.06-7.59h0a21.76,21.76,0,0,1,2.2-3.64c.39.9.77,1.8,1.16,2.69l.75,1.76c.33.75.65,1.51,1,2.26q1.4,3.22,2.78,6.45a27,27,0,0,0,4.21,7.51c1.91,2.16,4.72,3.73,7.58,3.42a9.18,9.18,0,0,0,6.46-4.5,26.56,26.56,0,0,0,2.87-7.59,24.16,24.16,0,0,0,.32-9.89c-1-6.33-3.65-12.79-5.6-18.67a18.83,18.83,0,0,1-1.36-6.15,3.42,3.42,0,0,1,.07-.71l0-.18a15.31,15.31,0,0,1,2.53-.92c.5-.14,1-.24,1.49-.33q2.58,14,5.17,27.94c0,.22.09.44.13.66.24,1.34.49,2.67.77,4s.52,2.41.82,3.6l0,0c.1.4.21.81.32,1.21h0c.31,1.1.65,2.18,1,3.26C582.79,287,586.86,293.51,585.37,300.22Z"
          transform="translate(-79.34 -161.25)"
          fill="#158cba"
        />
        <path
          d="M680.51,399.89a8.14,8.14,0,0,0,.77,4c1.46,2.68,5,3.29,8,3.54a237.62,237.62,0,0,0,53.37-1.62,6.25,6.25,0,0,0,2.72-.85c2.51-1.76,1-6.05-1.78-7.43s-6-1-9-1.63a28.61,28.61,0,0,1-8.44-3.66q-9.15-5.26-18-11c-3.53-2.27-7.7-4.69-11.67-3.34C685.69,381.54,680.43,388.28,680.51,399.89Z"
          transform="translate(-79.34 -161.25)"
          fill="#2f2e41"
        />
        <path
          d="M549.33,266h0l-.09.49-.15.82c-.49,2.52-1.1,5-1.79,7.53-1.91,6.89-4.46,13.68-7,20.36L519.89,349c-1.72,4.52-3.43,9-5.38,13.46-.65,1.47-1.32,3-2,4.46-2.39,5.37-4.69,10.92-5.45,16.65-.5,3.79-1,7.66-.25,11.41s3,7.42,6.61,8.8c1.74.68,4.23,1.59,3.75,3.4-.25,1-1.38,1.48-2.4,1.64a9.19,9.19,0,0,1-7.53-2.17c-1.9-1.68-3.37-4.31-5.9-4.59-1.75-.19-3.36.86-5,1.59-6.55,3-14.17.9-21.05-1.23.54-2.78,3.34-4.62,6.11-5.17s5.65-.17,8.49-.24a4.42,4.42,0,0,0,2.58-.67A4.75,4.75,0,0,0,493.9,394a99.75,99.75,0,0,0,4.05-16.6c.92-5.9,1.3-11.88,2.6-17.7,1-4.54,2.58-8.94,3.92-13.4a281.37,281.37,0,0,0,6.39-27.88,98.33,98.33,0,0,0,1.8-11.82c.23-3.3.12-6.62.44-9.9a76.76,76.76,0,0,1,2.66-12.62c1.22-4.51,2.43-9,3.65-13.5,1.8-6.66,3.73-13.57,8.29-18.74a17.61,17.61,0,0,1,12.66-6.05,12.11,12.11,0,0,1,2.69.29l.44.11a10.6,10.6,0,0,1,2.75,1.14c1.41-.9,2.12-.58,3.06.79a8.87,8.87,0,0,1,1.11,4.8A66.53,66.53,0,0,1,549.33,266Z"
          transform="translate(-79.34 -161.25)"
          fill="#fbbebe"
        />
        <path
          d="M550.87,271.31l-.06.06-.32.32-3.19,3.19-1.57-6.58-5.37-22.54a12.11,12.11,0,0,1,2.69.29,4,4,0,0,1,.19-.76l.25.87,1.82,6.19,3.45,11.75.57,1.94h0Z"
          transform="translate(-79.34 -161.25)"
          fill="#158cba"
        />
        <rect x="450.58" y="22.25" width="50.86" height="31.79" rx="12" fill="#3f3d56" />
        <circle cx="504.41" cy="23.05" r="16.29" fill="#3f3d56" />
        <path
          d="M583.36,168l-.6,0a16.29,16.29,0,0,1,0,32.55h.6a16.29,16.29,0,0,0,0-32.58Z"
          transform="translate(-79.34 -161.25)"
          opacity="0.1"
        />
        <path
          d="M511.14,168l.6,0a16.29,16.29,0,0,0,0,32.55h-.6a16.29,16.29,0,1,1,0-32.58Z"
          transform="translate(-79.34 -161.25)"
          opacity="0.1"
        />
        <path
          d="M581.28,212.1H540a4.77,4.77,0,0,1-4.77-4.76v3.17a4.78,4.78,0,0,0,4.77,4.77h41.32a4.78,4.78,0,0,0,4.77-4.77v-3.17A4.78,4.78,0,0,1,581.28,212.1Z"
          transform="translate(-79.34 -161.25)"
          opacity="0.1"
        />
        <path
          d="M721.32,378.85s2.58.45,3-1.64c.21-1-.5-7.42-1.31-13.84-.39-3.16-.81-6.31-1.15-8.83-.41-3.09-.71-5.22-.71-5.22l-.47-1.56-2.78-9.34-2.62-8.8-1.93-3.46-13.89,6.76-8.64,14.93,2.68,1.69.47.3L698.9,355l13.82,14.5,6.22,6.53.73.76Z"
          transform="translate(-79.34 -161.25)"
          fill="#ffe09c"
        />
        <path
          d="M710.4,320.22s-15.22,7.14-18.88,14.9c0,0-3.42,6.53-3.85,10,0,0-1,3.17,5.31,3.95,0,0,2.87-11.65,11.48-16.71l8.62-5.05Z"
          transform="translate(-79.34 -161.25)"
          fill="#e7c4c3"
        />
        <ellipse
          cx="707.08"
          cy="338.78"
          rx="5.17"
          ry="4.38"
          transform="matrix(0.26, -0.97, 0.97, 0.26, 118.32, 773.54)"
          fill="#ff7f8e"
        />
        <ellipse
          cx="708.49"
          cy="355.13"
          rx="5.17"
          ry="4.38"
          transform="translate(103.57 787.04) rotate(-75.08)"
          fill="#ff7f8e"
        />
        <path
          d="M698.9,355a5.42,5.42,0,0,0,3.23-3.75c.74-2.76-.56-5.51-2.9-6.13s-4.83,1.11-5.56,3.87a3.9,3.9,0,0,0-.13.57l.47.3Z"
          transform="translate(-79.34 -161.25)"
          fill="#ff7f8e"
        />
        <path
          d="M717.49,348.33a4,4,0,0,0,3.23-.57l-2.78-9.34a5.38,5.38,0,0,0-3.36,3.79C713.85,345,715.15,347.71,717.49,348.33Z"
          transform="translate(-79.34 -161.25)"
          fill="#ff7f8e"
        />
        <path
          d="M719.1,364.49a4.22,4.22,0,0,0,3.95-1.12c-.39-3.16-.81-6.31-1.15-8.83l-.13,0c-2.34-.63-4.83,1.11-5.57,3.87S716.77,363.87,719.1,364.49Z"
          transform="translate(-79.34 -161.25)"
          fill="#ff7f8e"
        />
        <path
          d="M718.94,376a6.12,6.12,0,0,0,.65-1.55c.74-2.76-.56-5.5-2.9-6.12a4.22,4.22,0,0,0-4,1.14Z"
          transform="translate(-79.34 -161.25)"
          fill="#ff7f8e"
        />
        <path
          d="M712.07,334.18l1.6.43a9.16,9.16,0,0,0-1.09,3.75,2.46,2.46,0,0,0,0,.26c.21,1.65-1.6-.43-1.6-.43S711.29,334.8,712.07,334.18Z"
          transform="translate(-79.34 -161.25)"
          fill="#94cc79"
        />
        <path
          d="M704.78,344.5l1.61.43a8.93,8.93,0,0,0-1.09,3.74,2.28,2.28,0,0,0,0,.26c.21,1.65-1.6-.43-1.6-.43S704,345.11,704.78,344.5Z"
          transform="translate(-79.34 -161.25)"
          fill="#94cc79"
        />
        <path
          d="M709.76,347.91l1.6.42a9.16,9.16,0,0,0-1.09,3.75,2.28,2.28,0,0,0,0,.26c.22,1.65-1.6-.43-1.6-.43S709,348.52,709.76,347.91Z"
          transform="translate(-79.34 -161.25)"
          fill="#94cc79"
        />
        <path
          d="M716.16,356.52l1.61.42a9,9,0,0,0-1.09,3.75,2.28,2.28,0,0,0,0,.26c.21,1.65-1.6-.43-1.6-.43S715.39,357.13,716.16,356.52Z"
          transform="translate(-79.34 -161.25)"
          fill="#94cc79"
        />
        <path
          d="M720.15,370.69l1.6.43a9.15,9.15,0,0,0-1.09,3.74,2.11,2.11,0,0,0,0,.26c.22,1.65-1.6-.42-1.6-.42S719.37,371.3,720.15,370.69Z"
          transform="translate(-79.34 -161.25)"
          fill="#94cc79"
        />
        <path
          d="M711.47,360.78l1.6.43A9,9,0,0,0,712,365a2.11,2.11,0,0,0,0,.26c.21,1.65-1.6-.42-1.6-.42S710.7,361.39,711.47,360.78Z"
          transform="translate(-79.34 -161.25)"
          fill="#94cc79"
        />
        <path
          d="M715.35,321.54s-2.29-5.19-5-1.32a18.36,18.36,0,0,0,3,8.8s8.29,22.55,7.6,26c0,0,3.27,21.78,2.46,23,0,0,.9,1.54,1.54-4.25s-2.17-22.8-2.17-22.8l-4.11-17.68-2.75-6Z"
          transform="translate(-79.34 -161.25)"
          fill="#e7c4c3"
        />
        <path
          d="M715.35,321.54s-2.29-5.19-5-1.32a18.36,18.36,0,0,0,3,8.8s8.29,22.55,7.6,26c0,0,3.27,21.78,2.46,23,0,0,.9,1.54,1.54-4.25s-2.17-22.8-2.17-22.8l-4.11-17.68-2.75-6Z"
          transform="translate(-79.34 -161.25)"
          fill="#fff"
          opacity="0.2"
        />
        <path d="M687.85,330.14s4.11,11.84,7.87,8.69l-3-15.47Z" transform="translate(-79.34 -161.25)" fill="#fbbebe" />
        <path
          d="M406.75,378.77S443,439.84,451.47,448c0,0,36.22,37.6,17.81,35-1.35-.19-27.86-22.6-29.41-26.28s-51.67-68.79-51.67-68.79l7.15-6.58Z"
          transform="translate(-79.34 -161.25)"
          fill="#a0616a"
        />
        <path
          d="M406.75,378.77S443,439.84,451.47,448c0,0,36.22,37.6,17.81,35-1.35-.19-27.86-22.6-29.41-26.28s-51.67-68.79-51.67-68.79l7.15-6.58Z"
          transform="translate(-79.34 -161.25)"
          opacity="0.1"
        />
        <path
          d="M439.9,578.22s-11.6,14.5-6.76,20.3,28.95,16.89,36.73,16.43c8.22-.48,35.77,1,36.74-11.6,0,0,1.93-5.8-23.2-6.77S466,569.51,466,569.51Z"
          transform="translate(-79.34 -161.25)"
          fill="#2f2e41"
        />
        <path
          d="M301.66,455.44s-11.6,39.63,23.2,46.4l37.7-1.93s53.17-10.64,67.68-2.9c0,0,5.8,1,3.86,8.7s2.9,59.94,2.9,59.94v17.4s32.87,0,36.74-3.87c0,0-2.9-15.47,0-28s-4.83-52.21-4.83-52.21l-.77-23.81a16.62,16.62,0,0,0-8.77-14.32,13,13,0,0,0-5.93-1.51s-65.74-10.63-88.94-8.7S301.66,455.44,301.66,455.44Z"
          transform="translate(-79.34 -161.25)"
          fill="#605d82"
        />
        <path
          d="M366.43,328.79s17.4,20.3,19.33,27.07S409,380,409,380s-15.47,11.6-12.57,17.4-20.3-19.33-20.3-19.33L349,338.46Z"
          transform="translate(-79.34 -161.25)"
          fill="#158cba"
        />
        <path
          d="M366.43,328.79s17.4,20.3,19.33,27.07S409,380,409,380s-15.47,11.6-12.57,17.4-20.3-19.33-20.3-19.33L349,338.46Z"
          transform="translate(-79.34 -161.25)"
          opacity="0.1"
        />
        <path
          d="M378,305.59s-8.7,28-8.7,38.67-36.74-2.9-35.77-8.7,21.27-27.07,22.24-35.77S378,305.59,378,305.59Z"
          transform="translate(-79.34 -161.25)"
          fill="#a0616a"
        />
        <path
          d="M349,324.92s-33.84-8.7-38.67,40.61,3.86,55.1-10.64,85.07,98.61,3.87,98.61,3.87-5.8-6.77-24.17-3.87l-8.7-26.1s-2.9-11.6,13.54-11.6,8.7-33.84,8.7-33.84-8.7-29-13.54-31.9S360.63,326.86,349,324.92Z"
          transform="translate(-79.34 -161.25)"
          opacity="0.1"
        />
        <path
          d="M349,323s-33.84-8.7-38.67,40.6,3.86,55.11-10.64,85.08,98.61,3.87,98.61,3.87-5.8-6.77-24.17-3.87l-8.7-26.1S362.56,411,379,411s8.7-33.84,8.7-33.84-8.7-29-13.54-31.9S360.63,324.92,349,323Z"
          transform="translate(-79.34 -161.25)"
          fill="#158cba"
        />
        <circle cx="289.99" cy="129.84" r="31.9" fill="#a0616a" />
        <polygon
          points="283.83 293.18 364 341.1 438.59 271.6 349.67 240.63 285.68 284.19 283.83 293.18"
          fill="#dadae5"
        />
        <polygon points="371.67 152.85 347.1 228.27 436.21 257.03 473.66 150.61 371.67 152.85" fill="none" />
        <polygon points="378.44 159.04 353.49 233.24 436.21 257.03 471.52 156.69 378.44 159.04" fill="#dadae5" />
        <polygon points="371.78 153.56 378.55 159.75 471.63 157.4 473.77 151.32 371.78 153.56" opacity="0.03" />
        <polygon points="347.21 228.97 371.78 153.56 378.55 159.75 354.23 232.09 347.21 228.97" opacity="0.03" />
        <polygon points="371.67 152.85 378.44 159.04 471.52 156.69 473.66 150.61 371.67 152.85" fill="#eff0f4" />
        <polygon points="347.1 228.27 371.67 152.85 378.44 159.04 354.12 231.38 347.1 228.27" fill="#eff0f4" />
        <polygon points="371.67 152.85 382.02 162.53 475.11 160.18 473.66 150.61 371.67 152.85" opacity="0.03" />
        <polygon points="347.1 228.27 371.67 152.85 382.02 162.53 357.7 234.87 347.1 228.27" opacity="0.03" />
        <polygon points="347.26 229.33 349.52 243.68 438.99 274.74 436.38 258.09 347.26 229.33" opacity="0.03" />
        <polygon points="281.12 276.49 347.26 229.33 350.15 243.67 285.18 291.99 281.12 276.49" opacity="0.03" />
        <polygon points="347.1 228.27 349.04 240.64 438.51 271.7 436.21 257.03 347.1 228.27" fill="#eff0f4" />
        <polygon points="280.95 275.42 347.1 228.27 349.67 240.63 284.7 288.95 280.95 275.42" fill="#eff0f4" />
        <polygon points="347.1 228.27 349.04 240.64 438.51 271.7 436.21 257.03 347.1 228.27" opacity="0.03" />
        <polygon points="280.95 275.42 347.1 228.27 349.67 240.63 284.7 288.95 280.95 275.42" opacity="0.03" />
        <polygon points="280.95 275.42 283.94 293.25 363.89 341.02 361.92 322.67 280.95 275.42" fill="#cccad6" />
        <path
          d="M396.72,433.85s-1.39,2.65.73,3.87c1,.58,8.23,2,15.49,3.36,3.56.66,7.13,1.3,10,1.8,3.5.62,5.92,1,5.92,1l1.84,0,11.12.21,10.46.2,4.43-.91L454.16,426l-13.24-14.55-2.77,2.34-.47.4-7.28,3.54-20.54,10-9.24,4.5-1.08.53Z"
          transform="translate(-79.34 -161.25)"
          fill="#ffe09c"
        />
        <path
          d="M464.19,442.24s-2.49-19-9.67-25.66c0,0-5.9-6-9.53-7.64,0,0-3.09-2.19-6.13,4.41,0,0,11.67,7.14,14.19,18.25l2.51,11.11Z"
          transform="translate(-79.34 -161.25)"
          fill="#e7c4c3"
        />
        <ellipse
          cx="445.17"
          cy="432.23"
          rx="5"
          ry="5.9"
          transform="translate(-238.06 413.51) rotate(-57.43)"
          fill="#ff7f8e"
        />
        <ellipse
          cx="426.93"
          cy="428.11"
          rx="5"
          ry="5.9"
          transform="translate(-243.01 396.23) rotate(-57.43)"
          fill="#ff7f8e"
        />
        <path
          d="M430.4,417.74a6.15,6.15,0,0,0,3,4.81c2.74,1.75,6.17,1.29,7.65-1s.47-5.63-2.28-7.38a6.65,6.65,0,0,0-.58-.33l-.47.4Z"
          transform="translate(-79.34 -161.25)"
          fill="#ff7f8e"
        />
        <path
          d="M431.2,440.23a4.6,4.6,0,0,0-.51,3.71l11.12.21a6.1,6.1,0,0,0-3-5C436.11,437.44,432.68,437.91,431.2,440.23Z"
          transform="translate(-79.34 -161.25)"
          fill="#ff7f8e"
        />
        <path
          d="M413.08,436.4a4.78,4.78,0,0,0-.14,4.68c3.56.66,7.13,1.3,10,1.8l.09-.13c1.48-2.32.46-5.63-2.28-7.38S414.57,434.08,413.08,436.4Z"
          transform="translate(-79.34 -161.25)"
          fill="#ff7f8e"
        />
        <path
          d="M400.62,432.25a7.09,7.09,0,0,0,1.46,1.24c2.74,1.75,6.17,1.29,7.65-1a4.77,4.77,0,0,0,.13-4.7Z"
          transform="translate(-79.34 -161.25)"
          fill="#ff7f8e"
        />
        <path
          d="M448.44,439.23l-1,1.59a10.31,10.31,0,0,0-3.69-2.47l-.29-.07c-1.87-.34,1-1.59,1-1.59S448.05,438.18,448.44,439.23Z"
          transform="translate(-79.34 -161.25)"
          fill="#94cc79"
        />
        <path
          d="M439.75,427.75l-1,1.6a10.29,10.29,0,0,0-3.69-2.48l-.29-.07c-1.86-.33,1-1.59,1-1.59S439.36,426.7,439.75,427.75Z"
          transform="translate(-79.34 -161.25)"
          fill="#94cc79"
        />
        <path
          d="M434.33,432l-1,1.59a10.31,10.31,0,0,0-3.69-2.47l-.29-.07c-1.87-.34,1-1.59,1-1.59S433.93,430.93,434.33,432Z"
          transform="translate(-79.34 -161.25)"
          fill="#94cc79"
        />
        <path
          d="M422.76,436l-1,1.6a10.29,10.29,0,0,0-3.69-2.48l-.29-.07c-1.87-.33,1-1.59,1-1.59S422.36,434.91,422.76,436Z"
          transform="translate(-79.34 -161.25)"
          fill="#94cc79"
        />
        <path
          d="M406,435.4,405,437a10.36,10.36,0,0,0-3.7-2.48l-.29-.07c-1.86-.33,1-1.59,1-1.59S405.59,434.34,406,435.4Z"
          transform="translate(-79.34 -161.25)"
          fill="#94cc79"
        />
        <path
          d="M419.75,429.4l-1,1.59a10.29,10.29,0,0,0-3.69-2.48l-.29-.07c-1.87-.33,1-1.59,1-1.59S419.35,428.34,419.75,429.4Z"
          transform="translate(-79.34 -161.25)"
          fill="#94cc79"
        />
        <path
          d="M461,447.17s6.43-.7,3.15-4.93a20.86,20.86,0,0,0-10.58.18s-27.37,1.22-30.92-.74c0,0-24.8-4-25.8-5.26,0,0-2,.44,4.08,3.14s25.53,5.52,25.53,5.52l20.63,1.64,7.42-.92Z"
          transform="translate(-79.34 -161.25)"
          fill="#e7c4c3"
        />
        <path
          d="M461,447.17s6.43-.7,3.15-4.93a20.86,20.86,0,0,0-10.58.18s-27.37,1.22-30.92-.74c0,0-24.8-4-25.8-5.26,0,0-2,.44,4.08,3.14s25.53,5.52,25.53,5.52l20.63,1.64,7.42-.92Z"
          transform="translate(-79.34 -161.25)"
          fill="#fff"
          opacity="0.2"
        />
        <path
          d="M441.5,437.36a6.16,6.16,0,0,0,.93,5.57c1.88,2.65,5.23,3.51,7.49,1.92s2.55-5,.66-7.7c-.13-.18-.27-.36-.41-.53l-.6.2Z"
          transform="translate(-79.34 -161.25)"
          fill="#ff7f8e"
        />
        <polygon points="361.92 322.67 436.21 257.03 438.49 271.52 363.89 341.02 361.92 322.67" fill="#cccad6" />
        <polygon points="445.85 260.77 436.21 257.03 473.66 150.61 480.8 155.65 445.85 260.77" fill="#cccad6" />
        <g opacity="0.1">
          <path
            d="M464.9,577.13a33.4,33.4,0,0,1,1.11-7.62l-.49.17A34,34,0,0,0,464.9,577.13Z"
            transform="translate(-79.34 -161.25)"
            fill="#fff"
          />
          <path
            d="M469.87,613c-7.78.46-31.9-10.64-36.73-16.44a5.07,5.07,0,0,1-1.12-2.74,6.06,6.06,0,0,0,1.12,4.68c4.83,5.8,28.95,16.89,36.73,16.43,8.22-.48,35.77,1,36.74-11.6a1.58,1.58,0,0,0-.13-1C504.26,613.91,477.88,612.55,469.87,613Z"
            transform="translate(-79.34 -161.25)"
            fill="#fff"
          />
        </g>
        <path
          d="M498.12,378.38s1.72-2,.16-3.44c-.75-.7-6.63-3.38-12.55-6-2.91-1.28-5.84-2.54-8.17-3.54l-4.85-2.07-1.57-.4-9.45-2.41-8.89-2.27-4-.11-1.34,15.39,8.39,15.08,2.82-1.44.49-.25,6.93-1.56L485.67,381l8.79-2,1-.23Z"
          transform="translate(-79.34 -161.25)"
          fill="#ffe09c"
        />
        <path
          d="M442.2,357.67s-1.69,16.72,3.1,23.85c0,0,3.84,6.28,6.6,8.44,0,0,2.2,2.48,6.13-2.54,0,0-8.54-8.44-8.45-18.43l.08-10Z"
          transform="translate(-79.34 -161.25)"
          fill="#e7c4c3"
        />
        <ellipse
          cx="456.43"
          cy="370.04"
          rx="4.38"
          ry="5.17"
          transform="translate(-208.12 261.64) rotate(-44.19)"
          fill="#ff7f8e"
        />
        <ellipse
          cx="471.18"
          cy="377.22"
          rx="4.38"
          ry="5.17"
          transform="translate(-208.95 273.95) rotate(-44.19)"
          fill="#ff7f8e"
        />
        <path
          d="M466.13,385.37a5.41,5.41,0,0,0-1.56-4.7c-2-2-5-2.34-6.75-.65s-1.52,4.71.47,6.76a5.25,5.25,0,0,0,.42.4l.49-.25Z"
          transform="translate(-79.34 -161.25)"
          fill="#ff7f8e"
        />
        <path
          d="M470,366a4,4,0,0,0,1.17-3.07l-9.45-2.41a5.36,5.36,0,0,0,1.53,4.83C465.21,367.41,468.23,367.7,470,366Z"
          transform="translate(-79.34 -161.25)"
          fill="#ff7f8e"
        />
        <path
          d="M484.66,372.92a4.23,4.23,0,0,0,1.07-4c-2.91-1.28-5.84-2.54-8.17-3.54l-.11.09c-1.73,1.69-1.52,4.72.47,6.77S482.93,374.61,484.66,372.92Z"
          transform="translate(-79.34 -161.25)"
          fill="#ff7f8e"
        />
        <path
          d="M494.46,379a5.71,5.71,0,0,0-1-1.35c-2-2.05-5-2.34-6.74-.65a4.18,4.18,0,0,0-1.06,4Z"
          transform="translate(-79.34 -161.25)"
          fill="#ff7f8e"
        />
        <path
          d="M455,363.4l1.19-1.15a9.11,9.11,0,0,0,2.66,2.85l.23.12c1.53.66-1.19,1.15-1.19,1.15S455.17,364.38,455,363.4Z"
          transform="translate(-79.34 -161.25)"
          fill="#94cc79"
        />
        <path
          d="M460.16,375l1.18-1.16a9.1,9.1,0,0,0,2.66,2.86l.23.11c1.53.67-1.19,1.16-1.19,1.16S460.28,375.92,460.16,375Z"
          transform="translate(-79.34 -161.25)"
          fill="#94cc79"
        />
        <path
          d="M465.63,372.43l1.19-1.16a9.1,9.1,0,0,0,2.66,2.86l.23.11c1.53.67-1.19,1.16-1.19,1.16S465.76,373.41,465.63,372.43Z"
          transform="translate(-79.34 -161.25)"
          fill="#94cc79"
        />
        <path
          d="M476.31,371.35l1.19-1.16a9.16,9.16,0,0,0,2.65,2.86l.24.12c1.52.66-1.19,1.15-1.19,1.15S476.44,372.33,476.31,371.35Z"
          transform="translate(-79.34 -161.25)"
          fill="#94cc79"
        />
        <path
          d="M490.52,375.21l1.19-1.16a9,9,0,0,0,2.65,2.86l.24.11c1.52.67-1.19,1.16-1.19,1.16S490.65,376.18,490.52,375.21Z"
          transform="translate(-79.34 -161.25)"
          fill="#94cc79"
        />
        <path
          d="M477.56,377.56l1.19-1.15a9.11,9.11,0,0,0,2.66,2.85,1,1,0,0,0,.23.12c1.53.66-1.19,1.16-1.19,1.16S477.69,378.54,477.56,377.56Z"
          transform="translate(-79.34 -161.25)"
          fill="#94cc79"
        />
        <path
          d="M445.88,354.09s-5.63-.69-3.68,3.58a18.4,18.4,0,0,0,9.07,2s23.61,4.46,26.25,6.84c0,0,20.37,8.38,21,9.68,0,0,1.79,0-2.85-3.51S475,362.82,475,362.82l-17.28-5.55-6.52-.71Z"
          transform="translate(-79.34 -161.25)"
          fill="#e7c4c3"
        />
        <path
          d="M445.88,354.09s-5.63-.69-3.68,3.58a18.4,18.4,0,0,0,9.07,2s23.61,4.46,26.25,6.84c0,0,20.37,8.38,21,9.68,0,0,1.79,0-2.85-3.51S475,362.82,475,362.82l-17.28-5.55-6.52-.71Z"
          transform="translate(-79.34 -161.25)"
          fill="#fff"
          opacity="0.2"
        />
        <path
          d="M417.09,377.38a29.06,29.06,0,0,0,7.69-5.85c1-1.18,1.84-2.51,2.87-3.67a18.84,18.84,0,0,1,9.68-5.52,47.34,47.34,0,0,1,11.21-1.09,20.57,20.57,0,0,1,7.46.9,7.49,7.49,0,0,1,4.94,5.31c.55,3-1.41,5.91-3.76,7.84a18.14,18.14,0,0,1-7.52,3.65,61.63,61.63,0,0,1-6.79.79c-13.38,1.4-25.32,8.68-37,15.31-10.3,5.83-16.14,5.56-26.83,10.62-.42-3.2-5.26-.72-5.19-3.95.06-2.41.18-6.09,2.46-7.51,2.65-1.65,7-2,10-3a161.11,161.11,0,0,0,20.15-8.15Q411.87,380.41,417.09,377.38Z"
          transform="translate(-79.34 -161.25)"
          fill="#a0616a"
        />
        <path
          d="M328.73,368.43,380,414.83s9.67-19.33,12.57-19.33-34.8-57-34.8-57-9.67-11.6-15.47-5.8S319.06,334.59,328.73,368.43Z"
          transform="translate(-79.34 -161.25)"
          opacity="0.1"
        />
        <path
          d="M327.76,366.5,379,412.9s9.67-19.34,12.57-19.34-34.81-57-34.81-57-9.67-11.61-15.47-5.81S318.09,332.66,327.76,366.5Z"
          transform="translate(-79.34 -161.25)"
          fill="#158cba"
        />
        <path
          d="M412.12,284.71a15.4,15.4,0,0,0,2.69-4.2c4.42-10-2.59-22.56-14.56-26-6-1.75-12.72-1.4-18.71-3.26-4.82-1.49-8.88-4.31-13.3-6.51A56.08,56.08,0,0,0,341.49,239c-9.05.32-18.89,3.5-22.6,10.49-5.42,10.18,4.55,23-1.49,32.91-4.44,7.28-15.21,9.43-24.14,12.59a65.88,65.88,0,0,0-24.64,15.27c-7,7-12,16.6-8.48,25.28,2.05,5.09,6.68,9.07,10.36,13.46a43,43,0,0,1,2.69,52.28c-1.72,2.47-3.73,4.88-4.44,7.68-1.7,6.68,5.16,13.4,13.09,14.7S298,422,304.17,417.63a40.15,40.15,0,0,0,13.31-15.48c7.33-15.54,2.18-33,1.45-49.73-.09-1.95,0-4.07,1.37-5.6,2.12-2.29,6.12-2.23,9.54-2.53,8.85-.78,17-5.74,20.75-12.58,7.17-13.16-1.18-31.55,11.35-41.45C377.71,277.8,400.41,297.5,412.12,284.71Z"
          transform="translate(-79.34 -161.25)"
          fill="#3f3d56"
        />
        <path
          d="M400.25,254.47l-.1,0c4.68,5.42,6.44,12.74,3.66,19.06a15.4,15.4,0,0,1-2.69,4.2c-11.71,12.79-34.41-6.91-50.18,5.55-12.53,9.9-4.18,28.29-11.35,41.45-3.72,6.84-11.9,11.8-20.75,12.58-3.42.3-7.42.24-9.54,2.53-1.41,1.53-1.46,3.65-1.37,5.6.73,16.73,5.88,34.19-1.45,49.73a40.15,40.15,0,0,1-13.31,15.48C287,415,278.78,418,270.84,416.7l-.55-.12a17.54,17.54,0,0,0,11.55,7.12c7.94,1.29,16.11-1.71,22.33-6.07a40.15,40.15,0,0,0,13.31-15.48c7.33-15.54,2.18-33,1.45-49.73-.09-1.95,0-4.07,1.37-5.6,2.12-2.29,6.12-2.23,9.54-2.53,8.85-.78,17-5.74,20.75-12.58,7.17-13.16-1.18-31.55,11.35-41.45,15.77-12.46,38.47,7.24,50.18-5.55a15.4,15.4,0,0,0,2.69-4.2C419.23,270.48,412.22,258,400.25,254.47Z"
          transform="translate(-79.34 -161.25)"
          opacity="0.1"
        />
      </svg>
      <h2 class="bottom-text lead text-center " [innerHTML]="'break.onBreak' | translate"></h2>
      <button
        class="d-flex align-items-center justify-content-center btn btn-outline-secondary mt-3"
        (click)="signOut()"
      >
        <svg class="logout-icon mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96C43 32 0 75 0 128V384c0 53 43 96 96 96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H96c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32h64zM504.5 273.4c4.8-4.5 7.5-10.8 7.5-17.4s-2.7-12.9-7.5-17.4l-144-136c-7-6.6-17.2-8.4-26-4.6s-14.5 12.5-14.5 22v72H192c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32H320v72c0 9.6 5.7 18.2 14.5 22s19 2 26-4.6l144-136z"
          />
        </svg>
        {{ 'common.signOut' | translate }}
      </button>
    </div>
  </div>
</div>
