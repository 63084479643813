import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AgentShiftStatus } from '../models/agent';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class BreakGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.agent$.pipe(
      map(agent => {
        // This should never happen since the auth guard should catch this first
        if (!agent) {
          return false;
        }

        if (agent.aid === 'GLOBAL' && agent.shift?.status === AgentShiftStatus.Break) {
          console.log('Agent is on break, routing to break page');
          return this.router.createUrlTree(['accounts', next.params.accountId, 'break']);
        }

        return true;
      })
    );
  }
}
