<div class="modal-header" ngbAutofocus>
  <h4 class="modal-title">Job Change</h4>
</div>
<div class="modal-body">
  <h5>
    The job you were sending on is no longer available to your agent. Your active job has changed.
  </h5>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.close()">Close</button>
</div>
