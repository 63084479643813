<app-conv-actions-footer-default
  #actionsFooterDefault
  *ngIf="currentlyDisplayedComponent === 'default'"
  (textEvent)="onSendMessage($event)"
  (gotoEvent)="onGoTo($event)"
  (aiSuggestedAnswer)="onAiSuggestedAnswer($event)"
  (aiSuggestedTemplate)="onAiSuggestedTemplate($event)"
  [lead]="conversation.lead"
  [sendButtonStatus]="sendButtonStatus"
  [aiSuggestion]="aiSuggestion"
>
</app-conv-actions-footer-default>
