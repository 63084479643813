<div class="d-flex flex-column justify-content-center align-items-center h-100 text-center">
  <div
    *ngIf="domain$ | async as domain; else loading"
    id="login-container"
    class="my-auto d-flex flex-column align-items-center px-3"
  >
    <img class="logo" [src]="domain.logo" />

    <h2 class="title-text">{{ domain.account_name }}</h2>

    <div class="form-section">
      <form class="d-flex flex-column align-items-center" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="input-group mb-3 w-100">
          <label for="email" class="sr-only">
            {{ 'common.email' | translate }}
          </label>

          <svg class="input-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              d="M0 128C0 92.65 28.65 64 64 64H448C483.3 64 512 92.65 512 128V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V128zM32 128V167.9L227.6 311.3C244.5 323.7 267.5 323.7 284.4 311.3L480 167.9V128C480 110.3 465.7 96 448 96H63.1C46.33 96 31.1 110.3 31.1 128H32zM32 207.6V384C32 401.7 46.33 416 64 416H448C465.7 416 480 401.7 480 384V207.6L303.3 337.1C275.1 357.8 236.9 357.8 208.7 337.1L32 207.6z"
            />
          </svg>

          <input
            autofocus
            class="form-control"
            formControlName="email"
            id="email"
            [placeholder]="'common.email' | translate"
            required
            type="email"
          />
        </div>

        <div class="input-group mb-3 w-100">
          <label for="password" class=" sr-only">
            {{ 'common.password' | translate }}
          </label>

          <svg class="input-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              d="M466.5 83.69l-192-80.01C269.6 1.656 261.3 0 256.1 0C250.8 0 242.5 1.656 237.6 3.688l-192 80.01C27.69 91.08 16 108.6 16 127.1C16 385.2 205.2 512 255.1 512C307.1 512 496 383.8 496 127.1C496 108.6 484.3 91.08 466.5 83.69zM256.1 48.03C256.1 48.02 256.2 48.03 256.1 48.03L256.1 48.03zM255.1 464C163.5 425.5 64.06 303.9 64.06 128.1c0-.05 0 .05 0 0l190.8-79.64c.332-.1016 .7422-.3021 1.275-.3997L448 127.1C448 330.5 322.4 436.3 255.1 464zM200 208c0 22.3 13.13 41.4 32 50.41V328C232 341.3 242.8 352 256 352s24-10.75 24-24V258.4c18.87-9.008 32-28.11 32-50.41c0-30.93-25.07-56-56-56S200 177.1 200 208z"
            />
          </svg>
          <input
            class="form-control"
            formControlName="password"
            id="password"
            [placeholder]="'common.password' | translate"
            required
            type="password"
          />
        </div>

        <a *ngIf="!domain.hide_password_reset" id="forgot-password-link" [routerLink]="['../requestreset']">
          {{ 'login.forgotPassword' | translate }}
        </a>

        <p *ngIf="error" class="mb-3 text-danger">
          {{ 'login.loginError' | translate }}
        </p>

        <!--Log In Button----------------------------------------------->
        <button class="signin-btn btn btn-lg" [disabled]="!form.valid || submitting" type="submit">
          <span *ngIf="!submitting" class="signin-text">
            {{ 'login.signIn' | translate }}
          </span>
          <app-spinner *ngIf="submitting" width="2rem" class="spinner"></app-spinner>
        </button>

        <p *ngIf="!domain.hide_agent_signup" class="new-user-text mt-3">
          {{ 'login.newUser' | translate }}
          <a class="sign-up-text ml-1" [routerLink]="['../signup']">
            {{ 'login.signUp' | translate }}
          </a>
        </p>
      </form>
    </div>
  </div>

  <ng-template #loading>
    <h2 class="my-auto">{{ 'common.loading' | translate }}...</h2>
  </ng-template>

  <app-footer class="footer"></app-footer>
</div>
