<footer class="d-flex justify-content-between py-2 px-3 w-100">
  <!--left side of the footer-->
  <button
    class="align-self-end d-flex flex-shrink-0 align-items-center mr-3 btn btn-sm btn-outline-secondary"
    (click)="onLogout()"
  >
    <svg class="logout-icon mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path
        d="M160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96C43 32 0 75 0 128V384c0 53 43 96 96 96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H96c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32h64zM504.5 273.4c4.8-4.5 7.5-10.8 7.5-17.4s-2.7-12.9-7.5-17.4l-144-136c-7-6.6-17.2-8.4-26-4.6s-14.5 12.5-14.5 22v72H192c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32H320v72c0 9.6 5.7 18.2 14.5 22s19 2 26-4.6l144-136z"
      />
    </svg>
    {{ 'common.signOut' | translate }}
  </button>

  <!--right side of the footer-->
  <div id="user-settings" class="d-flex flex-column align-items-end">
    <!-- settings area -->
    <ng-container *ngIf="currentSettings$ | async as currentSettings">
      <!-- NOTE: Commenting out for now, but need to change this to a link instead of dropdown. -->
      <!-- <div *ngIf="isCoreDomain" class="d-flex align-items-center mb-2">
        <div class="select-label">UI Version</div>

        <select
          class="select-dropdown form-control form-control-sm"
          (change)="onUiVersionChange($event.target.value)"
          [value]="currentSettings.ui_version"
        >
          <option value="1">Old UI</option>
          <option value="2">New UI</option>
        </select>
      </div> -->

      <div class="d-flex align-items-center mb-2">
        <div class="select-label">Theme</div>

        <select
          class="select-dropdown form-control form-control-sm"
          (change)="onThemeChange($event.target.value)"
          [value]="currentSettings.theme"
        >
          <option value="light">Light</option>
          <option value="dark">Dark</option>
          <!-- TODO: Add this back in later -->
          <!-- <option value="system">System</option> -->
        </select>
      </div>

      <div class="d-flex align-items-center mb-2">
        <div class="select-label">Language</div>

        <select
          class="select-dropdown form-control form-control-sm"
          (change)="onLanguageChange($event.target.value)"
          [value]="currentSettings.preferred_language"
        >
          <option value="en">English</option>
          <option value="fr-CA">Français</option>
          <option value="es-MX">Español</option>
        </select>
      </div>
    </ng-container>

    <small *ngIf="footerData$ | async as footerData" class="d-flex">
      <div class="email">{{ footerData.agentEmail }}</div>
      <div class="mx-1">&middot;</div>
      <div>v{{ version }}</div>
    </small>
  </div>
</footer>
