<div class="d-flex flex-column h-100">
  <div class="container my-auto">
    <div class="d-flex flex-column align-items-center">
      <h1 class="text-center mt-4">
        {{ 'noTeam.awaitingTeam' | translate }}
      </h1>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="863.6556"
        height="506.4464"
        viewBox="0 0 863.6556 506.4464"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g>
          <path
            id="uuid-7eb24f19-d190-4a71-9df2-28d9546a7028-887"
            d="M452.30785,191.35387c5.73837-7.26895,14.14035-10.20018,18.76578-6.54749s3.72229,12.50487-2.0191,19.77563c-2.2536,2.93584-5.17752,5.28928-8.52713,6.86343l-24.70261,30.49178-14.0618-11.89722,25.84534-28.79637c.75421-3.6246,2.36569-7.01582,4.69958-9.88976h-.00006v-.00002Z"
            fill="#ffb6b6"
          />
          <path
            d="M361.27599,178.30789l36.04324,61.25302,43.63364-30.14142,17.55112,7.0011s-31.00739,67.48877-57.74359,63.09915c-26.73618-4.38962-79.93542-64.78058-73.82874-85.86995,6.10666-21.08936,34.34436-15.34189,34.34436-15.34189h-.00003v-.00002Z"
            fill="#158cba"
          />
        </g>
        <rect
          x="456.29146"
          y="161.4034"
          width="21"
          height="49"
          transform="translate(-33.53914 139.82052) rotate(-16.45294)"
          fill="#158cba"
        />
        <polygon
          points="356.75167 179.83328 357.1824 151.10986 339.46951 140.0683 320.20886 163.62825 356.75167 179.83328"
          fill="#ffb6b6"
        />
        <polygon
          points="356.75167 179.83328 357.89816 151.82561 339.46951 140.0683 345.73065 174.72913 356.75167 179.83328"
          isolation="isolate"
          opacity=".1"
        />
        <circle cx="347.26592" cy="122.47031" r="31.50162" fill="#ffb6b6" />
        <path
          d="M399.76996,369.76641l-5.54999,37.72998-2.78998,19-11.62,79.06c-14.66003-.46002-29.13-1.12-43.38-1.95001l2.07001-48.14001,1.25-28.96997,.81995-19,.51001-11.96002-7.46997-28.52997s-2.46002-4.57001-4.96002-10.66003c-.44-1.07996-.89001-2.20996-1.32001-3.37-3.09998-8.28998-5.56-18.15997-2.46997-23.32001,.14996-.23999,.29999-.46997,.46997-.69,1.40002-1.81,5.03003-3.15997,9.96002-4.15997,3.01001-.62,6.52002-1.09998,10.29999-1.46997,11.44-1.13,25.41003-1.30005,36.10004-1.19,9.66998,.07996,16.64996,.37994,16.64996,.37994l.10004,3.19,.00995,.31,.07001,2.42004,.17004,5.77997,.42999,14.28003,.44,14.51001,.20996,6.75Z"
          fill="#2f2e41"
        />
        <path
          d="M399.56,363.01641l-15.57001,31.22998-9.85004,13.25-14.10999,19-21.52997,28.96997-34.14001,45.95001c-16.41998-1.27997-32.52002-2.81-48.23004-4.54999l41.79004-70.37,11.27997-19,10.89001-18.33002,6.91998-28.66998s0-1.53003,.11005-3.97998c.04999-1.03003,.12-2.23004,.20996-3.54004,.61005-8.75,2.46002-22.70001,7.96002-28.16998,.73999-.75,1.54999-1.33002,2.42999-1.72998,1.65002-.73999,4.40002-.56,7.87,.26001,8.58002,2.04999,21.53998,8.08997,33.17999,14.08997,8.04999,4.16003,15.46002,8.28998,20.35004,11.08002l.44,14.51001Z"
          fill="#2f2e41"
        />
        <path
          d="M324.2586,157.55148l35.78674,17.17764s37.93396,112.37042,37.93396,118.09628,10.73602,9.30457,4.2944,15.74619,11.98962,11.89697,2.05826,17.04239c-9.93134,5.14539-71.88467,33.2901-78.77359,30.75208-19-7,.80515-32.72174,.80515-32.72174,0,0-14.27243-11.49402-12.12524-17.21991,2.14722-5.72589-12.88324-115.23334-12.88324-115.23334l22.90353-33.63956,.00003-.00002v-.00002Z"
          fill="#158cba"
        />
        <path
          d="M349.91656,129.79185l7.90643,4.42403s-4.84906-14.34099-1.30984-20.66613,1.4761-10.80177,1.4761-10.80177c0,0,12.62399,4.1568,15.94858-1.04261s4.42404-7.90643-6.40405-12.30416c-10.82806-4.39773-6.75439-1.28776-6.75439-1.28776,0,0-9.2468-3.51292-7.20996-1.95794,2.03683,1.55499-7.6655-2.62812-6.29883-.61758s-6.0842-1.74331-4.71756,.26723c1.36667,2.01054-17.26263,4.87536-13.4299,6.18941,3.83276,1.31406-15.70764,2.83852-15.12061,18.11692s-10.05368,19.61217-2.20148,24.05293,18.81177,23.44669,18.81177,23.44669l9.24268-6.28268s-2.77759-24.56764,10.06104-21.53658h.00003v.00002Z"
          fill="#2f2e41"
        />
        <g>
          <path
            id="uuid-788a1427-1d14-4107-8598-91cdf159bec2-888"
            d="M282.80825,293.49045c9.18427-1.18973,16.01501-6.89294,15.2569-12.73776-.75812-5.84479-8.81714-9.61691-18.00452-8.42554-3.67651,.42557-7.19766,1.7254-10.26883,3.79077l-38.85049,5.53238,2.9848,18.17606,37.98282-7.38367c3.49731,1.21469,7.23471,1.57397,10.89932,1.04773v.00003Z"
            fill="#a0616a"
          />
          <path
            d="M164.03719,213.73125l10.65633,79.32826s2.9312,17.06137,29.61266,14.54916,61.74506-12.85992,61.74506-12.85992l-2.28174-18.52408-29.80118,3.22833s-13.91116-3.37491-16.67282-1.09891-1.49319,4.94052-4.2244,1.62653-1.35977-5.3717-3.75104-2.47351-2.87692,7.93588-4.3092,1.69644c-1.43227-6.23947-4.09679-4.97098-4.09679-4.97098l-1.51614-58.10919s-8.00505-27.71945-22.6266-18.45168-12.7341,16.05956-12.7341,16.05956h-.00003v-.00002Z"
            fill="#e6e6e6"
          />
        </g>
        <rect x="157.96699" y="150.4398" width="52.77629" height="77.98285" fill="#2f2e41" />
        <polygon
          points="169.81358 204.64456 171.6618 182.30898 196.23675 182.30898 201.68464 205.97305 169.81358 204.64456"
          fill="#a0616a"
        />
        <polygon
          points="169.81358 204.64456 171.6618 182.30898 196.23675 182.30898 201.68464 205.97305 169.81358 204.64456"
          isolation="isolate"
          opacity=".1"
        />
        <circle
          cx="187.81917"
          cy="162.5531"
          r="26.86263"
          transform="translate(-2.72025 321.90893) rotate(-80.78252)"
          fill="#a0616a"
        />
        <path
          d="M194.59528,134.29184s0-11.02788-10.24017-8.66476-13.39101,6.30164-13.39101,6.30164c0,0-3.93854,6.30164-10.24017,7.08936-6.30164,.7877-11.81558,18.90492-11.02788,22.84346s4.72623,14.1787-.7877,18.90494c-5.51395,4.72623-13.39101,11.81558-11.02788,18.90494,2.36311,7.08936,9.45247,25.20657,9.45247,25.20657,0,0-19.77327,2.89032-18.15753,15.23001,0,0,9.49278-7.35295,22.09607-7.35295s11.02788-22.86517,11.02788-22.86517c0,0,.7877-14.157,3.15082-18.09552s-.7877-25.99429,11.02788-37.80988,18.11723-19.69264,18.11723-19.69264v0Z"
          fill="#2f2e41"
        />
        <path
          d="M191.44447,131.92873s14.96642,3.15082,20.48036,18.11723,2.72906,17.75128,2.72906,17.75128c0,0,1.21912,9.0121,7.90979,12.17223s1.17673,16.55112,1.17673,16.55112c0,0,1.57541,13.39101,22.84348,18.11723,21.26804,4.72623-26.67056,40.96936-26.67056,40.96936,0,0,3.44666-17.10567-18.60912-17.89339-22.05576-.7877,4.31898-41.19322,4.31898-41.19322l2.36313-25.99429s-4.72623-29.93282-18.90494-33.87134c-14.1787-3.93854,2.36311-4.72623,2.36311-4.72623l-.00003,.00002Z"
          fill="#2f2e41"
        />
        <path
          d="M229.57996,370.48638l-5.44,37.01001-2.78998,19-4.72998,32.14001-4.79004,32.57001c-5.66998-.82001-11.26996-1.67004-16.79999-2.55005-9.5-1.50995-18.79999-3.09998-27.90002-4.77997l1.77002-41.33002,.69-16.04999,.82001-19,.47998-11.25-7.47998-28.53003s-15.42999-28.72998-8.27002-38.02997c.33002-.41998,.77002-.82001,1.33002-1.19,1.82001-1.22998,4.82001-2.21002,8.59003-2.97003,8.90997-1.81995,22.10999-2.47998,34.13995-2.64996,15.44-.22003,28.94,.37,28.94,.37l.08002,2.34998,.15002,5.10004,1.20996,39.78998Z"
          fill="#2f2e41"
        />
        <path
          d="M229.57996,370.48638l-5.44,37.01001-2.78998,19-4.72998,32.14001-21.59003,30.01996c-9.5-1.50995-18.79999-3.09998-27.90002-4.77997-6.68994-1.22998-13.25995-2.51001-19.72998-3.83002l21.5-37.5,9.20001-16.04999,10.88-19,5.22998-9.12,4.54999-7.94,3.33002-14.79999,2.56-11.35004,.59003-2.62s-.06-4.12,.32996-9.64996c.60004-8.38,2.25-20.02002,6.79004-25.49005,.88-1.07996,1.88-1.90997,3.01001-2.43994,2.35999-1.11005,7.01996-.35004,12.84998,1.50995l.15002,5.10004,1.20996,39.78998Z"
          fill="#2f2e41"
        />
        <path
          d="M203.45139,204.78999l-.65553-.18526-32.26826-9.11929-25.76646,35.07101,17.17764,73.00494-6.44162,29.34515s52.24866-13.59897,73.00497-2.14722c0,0,1.43146-6.52615,0-9.70471s-3.57867-16.40555-1.43146-22.31741-4.2944-33.82553-4.2944-33.82553c0,0,2.14722-18.60912-7.15735-27.91367-9.30457-9.30457-12.1675-32.20808-12.1675-32.20808l-.00003,.00002v.00005Z"
          fill="#e6e6e6"
        />
        <path
          id="uuid-cd6b1305-cf39-49fc-bef1-740b2c9d0d0e-889"
          d="M194.49477,399.54056c2.31381,8.96732,8.81625,15.04218,14.52286,13.56873s8.45557-9.93631,6.13971-18.90652c-.87592-3.59592-2.60028-6.92978-5.02878-9.72263l-10.28355-37.87106-17.66891,5.20459,12.01364,36.78159c-.77391,3.62045-.66933,7.37363,.30504,10.94534v-.00003Z"
          fill="#a0616a"
        />
        <path
          d="M199.18043,237.02419s-10.00787-27.04489-20.97638-25.28409-20.96851,18.7608-20.96851,18.7608l7.92912,96.20671,21.77953,54.90134,23.10237-9.10803-12-54.86758,1.13387-80.60916v.00002Z"
          fill="#e6e6e6"
        />
        <path
          d="M699.54999,69.99639V484.62639c-.33002,.07001-.66003,.13-1,.17999-5.07001,.91998-10.21002,1.79999-15.41003,2.65997-6.17999,1.02002-12.44995,2.01001-18.79999,2.96002-5.27997,.78003-10.60999,1.53998-16,2.27002-15.69,2.14996-31.82001,4.06-48.33997,5.71997-5.29004,.54004-10.63,1.04999-16,1.53003-46.95001,4.21997-96.85004,6.5-148.59003,6.5-7.33002,0-14.60999-.04999-21.85999-.14001-.34003,0-.67004,0-1-.01001V69.99639h287Z"
          fill="#f2f2f2"
          isolation="isolate"
          opacity=".7"
        />
        <path
          d="M698.54999,206.78637v1.23004l.13-.26001c-.01001-.32001-.06-.64001-.13-.97003Zm-286,130.64001l-1-.81v89.88h1v79.79999c.32996,.01001,.65997,.01001,1,.01001v-229.5c-.33002,.17999-.66003,.34998-1,.52002v60.09998Zm-1-268.42999V506.27636c.32996,.01001,.65997,.02002,1,.02002,.32996,.01001,.65997,.01001,1,.01001V70.99639h285V484.80638c.33997-.04999,.66998-.10999,1-.17999,.32996-.04999,.66998-.10999,1-.17999V68.99639H411.54999Z"
          fill="#3f3d56"
        />
        <circle cx="437.04656" cy="364.50092" r="19" fill="#3f3d56" />
        <g>
          <path
            id="uuid-340fa4d6-17c0-4cd5-bee8-12f32296eb37-890"
            d="M434.58685,359.23642c6.8204,6.26486,15.58142,7.82388,19.56769,3.48267s1.68811-12.93744-5.13556-19.20361c-2.69144-2.54047-5.95215-4.39944-9.50931-5.42139l-29.23358-26.17947-11.99286,13.98032,30.09241,24.32407c1.32056,3.45874,3.45038,6.55081,6.21124,9.01743h-.00003Z"
            fill="#ffb6b6"
          />
          <path
            d="M350.09387,220.33192s-23.11182-17.24606-31.35663-9.8008-7.38541,27.14961-7.38541,27.14961l58.99832,76.40547,48.14508,34.21338,14.4278-20.21173-39.92145-39.50607-42.90768-68.24991-.00003,.00002v.00003Z"
            fill="#158cba"
          />
        </g>
        <path d="M600,392.49639v105.91998c-5.29004,.54004-10.63,1.04999-16,1.53003v-107.45001h16Z" fill="#3f3d56" />
        <path d="M16,392.49639v48.09998c-5.59003-2.46997-10.93005-5.00995-16-7.59998v-40.5H16Z" fill="#3f3d56" />
        <rect x="10.99539" y="407.49805" width="582.99999" height="19" fill="#158cba" />
        <path
          d="M723.18209,126.67004s-10.66663,6.25552-7.16833,12.44181c3.49823,6.18629,8.37512,14.61276,5.06659,17.26659s-8.77094,14.35461-8.77094,14.35461c0,0-23.31525-3.25642-21.1264,14.8546s13.14197,20.58018,3.75385,28.54768,9.75708,17.49655,9.75708,17.49655c0,0,13.21704-4.11877,16.23242-16.78452s-2.68805,9.68456-.77521,11.28712,9.34009,12.16576,14.59564-1.8786,.05133-19.48965,6.99573-27.77847c6.9444-8.28883,11.66577-21.6956,11.45892-24.03918s7.9436-23.72893-4.60162-32.06908-25.41779-13.69911-25.41779-13.69911h.00006Z"
          fill="#2f2e41"
        />
        <g>
          <path
            id="uuid-a9d6e0e2-9081-4547-a7f7-3829edb971db-891"
            d="M632.64181,324.17535c-5.34741,6.02509-12.79071,8.14978-16.62451,4.74603-3.83386-3.40375-2.60614-11.04593,2.74396-17.07242,2.10413-2.43665,4.76123-4.33398,7.7489-5.53323l22.97955-25.24237,11.61566,11.03613-23.88702,23.7128c-.83655,3.10992-2.40582,5.97421-4.5766,8.35306h.00006Z"
            fill="#ffb6b6"
          />
          <path
            d="M699.83791,205.40999s13.93671-12.80269,19.81653-8.49271,6.63483,17.23132,6.63483,17.23132l-33.41388,53.82167-48.19244,40.6203-10.7691-12.23157,42.47015-43.52635,23.45392-47.42264v-.00003Z"
            fill="#3f3d56"
          />
        </g>
        <polygon
          points="728.08151 176.40269 720.53176 203.23746 761.96841 203.23746 748.32369 170.17296 728.08151 176.40269"
          fill="#ffb6b6"
        />
        <polygon
          points="728.08151 176.40269 720.53176 203.23746 761.96841 203.23746 748.32369 170.17296 728.08151 176.40269"
          isolation="isolate"
          opacity=".1"
        />
        <polygon
          points="749.09292 264.95834 773.39028 322.35718 691.16372 318.29639 696.7524 264.95834 749.09292 264.95834"
          fill="#ffb6b6"
        />
        <polygon
          points="725.3243 189.49797 759.61056 188.06937 782.46804 228.07001 762.46774 292.35672 692.46664 299.49969 700.04659 237.50093 703.89541 202.35532 725.3243 189.49797"
          fill="#3f3d56"
        />
        <path
          d="M782.46997,376.20641c-1.65002,7.98999-3.44,18.94-5.19,30.81-.90997,6.17999-1.81,12.60999-2.66998,19-2.22003,16.38995-4.20001,32.51996-5.51001,43.5-10.88,2.82001-22.19,5.50995-33.90002,8.04999l-6.04999-51.54999-2.22998-19-2.31-19.66003-1.04004,19.66003-1.00995,19-2.90002,54.78998c-6.27002,1.26001-12.64001,2.46997-19.10999,3.64001-.33002,.07001-.67004,.13-1,.17999-.33002,.07001-.66003,.13-1,.17999-5.07001,.91998-10.21002,1.79999-15.41003,2.65997l-.85999-61.44995-.27002-19-.96997-68.94,8.45001-27.92004,2.54999-.22998,6.51001-.58997,1-.09003,1-.09998,64.76001-5.88,2.15997-.20001s1.53998,3.01001,3.65997,8.02002c.18005,.41998,.37006,.85999,.56006,1.31,1.82996,4.39996,4.00995,10.10999,6,16.56,2.62994,8.47998,4.93994,18.23999,5.70996,27.94995,.52002,6.59003,.34003,13.15002-.92999,19.30005Z"
          fill="#2f2e41"
        />
        <g>
          <path
            id="uuid-de836ca6-962e-4f27-b85c-b36b0bd5804b-892"
            d="M784.9988,350.1416c-.60242,8.03326-5.23566,14.23407-10.34796,13.84988-5.11237-.38419-8.76703-7.20724-8.16333-15.24329,.19708-3.21338,1.16089-6.33282,2.81073-9.09735l2.98218-34.00507,15.92773,1.73959-4.63092,33.33826c1.21924,2.98074,1.70667,6.21017,1.42157,9.41797Z"
            fill="#ffb6b6"
          />
          <path
            d="M766.4775,214.94733s3.32751-18.62975,10.61658-18.7646,15.72009,9.686,15.72009,9.686l6.03705,63.062-13.71875,61.51678-15.97882-3.20355,7.40588-60.36069-10.08197-51.93596-.00006,.00003v-.00002Z"
            fill="#3f3d56"
          />
        </g>
        <circle cx="737.92617" cy="157.28004" r="24.95403" fill="#ffb6b6" />
        <path
          d="M728.3605,129.41473s-4.159,11.6452,2.49542,14.14061,15.8042,5.8226,14.97241,9.98161c-.83179,4.159,2.49542,16.63602,2.49542,16.63602,0,0-19.9632,12.47701-6.65442,24.95403,13.30884,12.47701,23.29041,7.33557,21.21094,19.472-2.07953,12.13643,18.71552,7.14563,18.71552,7.14563,0,0,7.48621-11.6452,1.66357-23.29042-5.82257-11.64522,4.159,9.14981,6.65442,9.14981s14.97241,3.32719,9.98163-10.81342-12.47699-14.97241-12.47699-25.78581-4.99078-24.12222-6.65442-25.78583c-1.66357-1.6636-9.14978-23.29042-24.12219-21.62682s-28.28119,5.8226-28.28119,5.8226h-.00012v-.00002Z"
          fill="#2f2e41"
        />
        <rect
          x="602.99537"
          y="291.4981"
          width="21"
          height="49"
          transform="translate(-64.37843 186.69816) rotate(-16.45294)"
          fill="#158cba"
        />
        <rect
          x="272.99538"
          y="251.49807"
          width="21"
          height="49"
          transform="translate(-66.56193 91.59513) rotate(-16.45294)"
          fill="#158cba"
        />
        <g>
          <path
            d="M664.33997,392.01641v98.40997c-5.27997,.78003-10.60999,1.53998-16,2.27002v-100.67999h16Z"
            fill="#3f3d56"
          />
          <rect x="659.34115" y="407.01465" width="204.31445" height="19" fill="#158cba" />
        </g>
        <circle cx="348.85635" cy="52.48704" r="24.07923" fill="#e6e6e6" />
        <circle cx="752.16348" cy="90.46663" r="24.07923" fill="#e6e6e6" />
        <path
          d="M355.44265,58.62005c-1.28992-.06689-2.58002-.09656-3.87012-.10669,.85614-4.81653,1.71222-9.63318,2.56836-14.44977,.19666-1.10638-.13043-2.2276-1.14886-2.82324-.97137-.56818-2.22626-.37732-3.0296,.39093-2.37701,2.27332-4.75409,4.54663-7.13116,6.81995-.97595,.93335-.9397,2.59576,0,3.53552,.9751,.97504,2.55682,.93604,3.53552,0,.52539-.50244,1.05072-1.00488,1.57611-1.50732l-1.44763,8.14417c-1.40973,.07031-2.81873,.16205-4.22522,.30023-1.34369,.13202-2.5,1.0545-2.5,2.5,0,1.38,1.14465,2.46985,2.5,2.5,2.07721,.0462,4.15442,.09241,6.23169,.13867,.51715,.01154,1.08624-.19714,1.54535-.54254,1.79822-.01703,3.59717,.00684,5.39557,.1001,1.34973,.07001,2.5-1.19916,2.5-2.5,0-1.41205-1.14624-2.42981-2.5-2.5h0Z"
          fill="#fff"
        />
        <g>
          <circle cx="543.06692" cy="24.07923" r="24.07923" fill="#158cba" />
          <path
            d="M549.65322,30.21224c-1.28992-.06689-2.58002-.09656-3.87012-.10669,.85614-4.81653,1.71222-9.63318,2.56836-14.44977,.19666-1.10638-.13043-2.2276-1.14886-2.82324-.97137-.56818-2.22626-.37732-3.0296,.39093-2.37701,2.27332-4.75409,4.54663-7.13116,6.81995-.97595,.93335-.9397,2.59576,0,3.53552,.9751,.97504,2.55682,.93604,3.53552,0,.52539-.50244,1.05072-1.00488,1.57611-1.50732l-1.44763,8.14417c-1.40973,.07031-2.81873,.16205-4.22522,.30023-1.34369,.13202-2.5,1.0545-2.5,2.5,0,1.38,1.14465,2.46985,2.5,2.5,2.07721,.0462,4.15442,.09241,6.23169,.13867,.51715,.01154,1.08624-.19714,1.54535-.54254,1.79822-.01703,3.59717,.00684,5.39557,.1001,1.34973,.07001,2.5-1.19916,2.5-2.5,0-1.41205-1.14624-2.42981-2.5-2.5h0Z"
            fill="#fff"
          />
        </g>
        <g>
          <circle cx="189.09579" cy="90.42105" r="24.07923" fill="#e6e6e6" />
          <path
            d="M196.41998,97.24639c-2.07001-1.07001-4.33002-1.64001-6.60004-1.72003,1.77002-2.84998,2.99005-6.04999,3.47003-9.34998,.27997-1.96997,.31-3.94-1.08002-5.52997-1.06-1.22003-2.58997-1.69-4.14001-1.84003-1.25995-.12-2.75995-.03998-3.94995,.35999-2.09003,.67999-2.78003,2.48004-2.73004,4.53003,.05005,1.95001,.35004,3.92999,1.66003,5.45996,.89996,1.05005,2.20996,1.74005,3.57996,1.85004-1.25995,2.59998-3.06995,4.89996-5.35999,6.83002-.95001,.78998-.91998,2.37994-.20001,3.27997,.78003,.97998,2.11005,1.26001,3.23004,.64001,2.94-1.64001,6.62-1.73004,9.59998-.19,1.20001,.62,2.71002,.31,3.41998-.90002,.66003-1.12,.31-2.79999-.89996-3.41998Zm-7.94-12.79999c0,.15997-.01001,.31-.03003,.46997-.00995,.09003-.01996,.17004-.01996,.22003-.01001,.04999-.01001,.08997-.02002,.09998v.01001c-.03998,.25-.09998,.48999-.14001,.72998-.39996-.10999-.82001-.12-1.22998,.02002-.03998,.01001-.06,.01001-.09003,.02002h-.02997c-.08002-.06-.14001-.11005,.02002-.01001-.09003-.10004-.14001-.16003-.17004-.17999l-.07001-.13c.01001,.03998,.01001,.07001,.01001,.07996-.01996-.02997-.04999-.12-.09998-.27997-.04999-.17999-.10004-.37-.14001-.56-.06-.29999-.07001-.69-.08002-1.13,.04004,0,.08002-.01001,.09003-.01001,.28998-.02002,.57001-.02997,.85999-.02997,.19,0,.38,.01996,.57001,.02997,.04999,.02002,.37,.09003,.48999,.12,.02002,.02002,.04999,.04004,.07001,.04999,.02002,.03003,.03003,.05005,.06,.08002l-.04999,.40002Z"
            fill="#fff"
          />
        </g>
        <path
          d="M755.51996,90.14641c1.67999-1.29004,3.01001-3.19,2.87-5.39001-.26001-4.06-4.23999-5.91003-7.76001-6.59003-1.32996-.26001-2.69995,.37-3.07996,1.73999-.33002,1.23004,.41998,2.82001,1.75,3.08002,.57001,.10999,1.14001,.23999,1.70996,.41003,.29004,.07996,.58002,.17999,.86005,.29999,.07996,.03998,.37,.16998,.41998,.20001,.22998,.12,.46997,.25995,.69,.41998,.02002,.02997,.14001,.14001,.21997,.21997-.17999-.13995,.16003,.39001,.16003,.26001,.01996,.04999,.02997,.09003,.03998,.13v.09003c0,.00995-.06,.20996-.03998,.20996-.16003,.26001-.22003,.38-.32001,.48004-.42999,.47998-.72998,.65997-1.35999,.95996-1.46002,.71002-3.07001,1.09003-4.53003,1.83002-2.53998,1.29999-.77002,5.34998,1.92999,4.57001,.13-.03003,.25-.07001,.38-.10004,.07001-.00995,.19-.02997,.27002-.02997,.16998,0,.33002,0,.48999,.01001h.06c.33002,.07001,.66003,.14996,.97003,.27997,.03998,.02002,.09998,.04999,.14996,.09003,.14001,.08002,.29004,.16998,.42999,.25l.16003,.10999,.01001,.01001c.12994,.09998,.23999,.21997,.35999,.33002,.01996,.01996,.13,.14996,.19,.20996,.04999,.07001,.14996,.20001,.15997,.22003,.09003,.14996,.17999,.31,.26001,.46997,.01001,.03003,.02002,.08002,.03003,.13,.01996,.10004,.04999,.21002,.07996,.32001-.01001,.03998,.01001,.21002,.01001,.32001,0,.02997,0,.04999,.01001,.07001-.03003,.13-.07001,.26001-.09998,.39001-.01001,.02997-.01001,.03998-.01001,.04999-.09003,.16998-.19,.32996-.28003,.48999-.01001,.02002-.02997,.02997-.03998,.04999v.01001c-.10999,.10999-.20001,.21002-.31,.32001-.07001,.07001-.16003,.14001-.23999,.20996-.06,.03003-.22003,.15002-.26001,.17004-.14001,.07001-.27002,.14996-.41003,.21997h-.00995c-.01001,.01001-.02002,.01001-.03003,.01001-.19,.06-.37,.10999-.54999,.16003h-.03003c-.16998,0-.57001-.05005-.57001-.03003-.06-.01001-.13995-.03003-.19-.03998-.10999-.06-.31995-.17004-.31995-.17004-2.77002-1.65997-5.29004,2.67004-2.53003,4.32001,4.47998,2.67999,10.58002-.91998,10.85999-6,.13-2.31-.92999-4.33997-2.56-5.76996Z"
          fill="#fff"
        />
      </svg>
      <h2
        class="bottom-text lead text-center "
        [innerHTML]="((supplementalCanvasser | async) ? 'noTeam.supplementalCanvasser' : 'noTeam.wait') | translate"
      ></h2>
      <button
        class="d-flex align-items-center justify-content-center btn btn-outline-secondary mt-3"
        (click)="signOut()"
      >
        <svg class="logout-icon mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96C43 32 0 75 0 128V384c0 53 43 96 96 96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H96c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32h64zM504.5 273.4c4.8-4.5 7.5-10.8 7.5-17.4s-2.7-12.9-7.5-17.4l-144-136c-7-6.6-17.2-8.4-26-4.6s-14.5 12.5-14.5 22v72H192c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32H320v72c0 9.6 5.7 18.2 14.5 22s19 2 26-4.6l144-136z"
          />
        </svg>
        {{ 'common.signOut' | translate }}
      </button>
    </div>
  </div>
</div>
