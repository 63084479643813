<footer id="sticky-footer-logOut" class="d-flex justify-content-between py-2 px-3 align-items-center">
  <!--left side of the footer-->
  <div class="leftSide-logOut">
    <small class=""> {{ 'common.agentUi' | translate }} v{{ version }} </small>
  </div>

  <!--right side of the footer-->
  <div class="right-side d-flex flex-column align-items-center">
    <ng-container *ngIf="currentSettings$ | async as currentSettings">
      <div class="d-flex">
        <div class="select-label">Language</div>
        <select
          id="language-select"
          class="select-dropdown form-control form-control-sm"
          (change)="onLanguageChange($event.target.value)"
          [value]="this.currentLanguage$ | async"
        >
          <option value="en">English</option>
          <option value="fr-CA">Français</option>
          <option value="es-MX">Español</option>
        </select>
      </div>

      <div class="theme-bottom d-flex align-items-center mt-2">
        <div class="select-label">Theme</div>
        <select
          class="select-dropdown form-control form-control-sm"
          (change)="onThemeChange($event.target.value)"
          [value]="currentSettings.theme"
        >
          <option value="light">Light</option>
          <option value="dark">Dark</option>
          <!-- <option value="system">System</option> -->
        </select>
      </div>
    </ng-container>

    <swal
      #confirmLogoutSwal
      html="{{ 'logout.loggingOut' | translate }}"
      html="{{ 'logout.draftsRemaining' }}"
      [showCancelButton]="true"
      cancelButtonText="{{ 'common.cancel' | translate }}"
      [focusCancel]="true"
      confirmButtonText="{{ 'common.logout' | translate }}"
      [reverseButtons]="true"
    >
    </swal>
  </div>
</footer>
