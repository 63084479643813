<div
  *ngIf="indicatorStatus$ | async as status"
  class="indicator"
  [ngClass]="status"
  [ngbPopover]="popContent"
  [popoverTitle]="popTitle"
  container="body"
></div>

<ng-template #popTitle>
  <div>{{ 'transIndicator.sendingSpeed' | translate }}</div>
</ng-template>
<ng-template #popContent>
  <div class="d-flex align-items-center">
    <div class="indicator indicator-small bad mr-2"></div>
    <div>
      <div>{{ 'transIndicator.badText' | translate }}</div>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <div class="indicator indicator-small good mr-2"></div>
    <div>
      <div>{{ 'transIndicator.goodText' | translate }}</div>
    </div>
  </div>
</ng-template>
