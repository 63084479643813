<!--This is the buttons for the End Conversation and Opt out/ for Ipad screens-->
<ng-container *ngIf="data$ | async as data">
  <div class="d-flex d-md-none justify-content-around align-items-center py-1">
    <!--END Button-->
    <button
      class="icon end-icon btn d-flex flex-column align-items-center py-3"
      (click)="onCompletedConversation(data.currentConv)"
    >
      <svg class="mb-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path
          d="M368 224c-17.62 0-32 14.38-32 32s14.38 32 32 32s32-14.38 32-32S385.6 224 368 224zM552 464H480V64c0-35.2-28.8-64-64-64H160C124.8 0 96 28.8 96 64v400H24C10.75 464 0 474.7 0 488C0 501.3 10.75 512 24 512h528c13.25 0 24-10.75 24-24C576 474.7 565.3 464 552 464zM432 464h-288V64c0-8.838 7.164-16 16-16h256c8.836 0 16 7.162 16 16V464z"
        />
      </svg>

      {{ data.currentConv.isCompleted ? ('convNavbar.ended' | translate) : ('convNavbar.end' | translate) }}
    </button>

    <!--OPT OUT Button-->
    <button
      class="icon opt-out-icon btn d-flex flex-column align-items-center py-3"
      (click)="onTriggerOptOut(data.currentConv)"
      [disabled]="data.currentConv.hasOptedOut"
    >
      <svg class="mb-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          d="M329.5 177.8l-11.3-11.3c-6.2-6.2-16.4-6.2-22.6 0L256 206.1l-39.6-39.6c-6.2-6.2-16.4-6.2-22.6 0l-11.3 11.3c-6.2 6.2-6.2 16.4 0 22.6l39.6 39.6-39.6 39.6c-6.2 6.2-6.2 16.4 0 22.6l11.3 11.3c6.2 6.2 16.4 6.2 22.6 0l39.6-39.6 39.6 39.6c6.2 6.2 16.4 6.2 22.6 0l11.3-11.3c6.2-6.2 6.2-16.4 0-22.6L289.9 240l39.6-39.6c6.3-6.2 6.3-16.4 0-22.6zM256 32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5c-6.6 7-8.4 17.2-4.6 26S14.4 480 24 480c61.5 0 110-25.7 139.1-46.3C192 442.8 223.2 448 256 448c141.4 0 256-93.1 256-208S397.4 32 256 32zm0 368c-26.7 0-53.1-4.1-78.4-12.1l-22.7-7.2-19.5 13.8c-14.3 10.1-33.9 21.4-57.5 29 7.3-12.1 14.4-25.7 19.9-40.2l10.6-28.1-20.6-21.8C69.7 314.1 48 282.2 48 240c0-88.2 93.3-160 208-160s208 71.8 208 160-93.3 160-208 160z"
        />
      </svg>

      {{ data.currentConv.hasOptedOut ? ('convNavbar.optedOut' | translate) : ('convNavbar.optOut' | translate) }}
    </button>
  </div>

  <!------------------------------------------->
  <!-- This only shows for MD and Desktop screens and up -->
  <div class="d-none d-md-flex justify-content-around align-items-center">
    <ng-container *ngIf="data.dispositions && !data.currentConv.isCompleted; else defaultEndButton">
      <div ngbDropdown placement="top-left" class="d-inline-block">
        <button ngbDropdownToggle class="icon end-icon d-flex flex-column align-items-center btn">
          <svg class="mb-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path
              d="M368 224c-17.62 0-32 14.38-32 32s14.38 32 32 32s32-14.38 32-32S385.6 224 368 224zM552 464H480V64c0-35.2-28.8-64-64-64H160C124.8 0 96 28.8 96 64v400H24C10.75 464 0 474.7 0 488C0 501.3 10.75 512 24 512h528c13.25 0 24-10.75 24-24C576 474.7 565.3 464 552 464zM432 464h-288V64c0-8.838 7.164-16 16-16h256c8.836 0 16 7.162 16 16V464z"
            />
          </svg>

          {{
            data.currentConv.isCompleted ? ('convNavbar.ended' | translate) : ('convNavbar.endConversation' | translate)
          }}
        </button>

        <div ngbDropdownMenu>
          <button
            ngbDropdownItem
            *ngFor="let disposition of data.dispositions"
            (click)="onCompletedConversation(data.currentConv, disposition)"
          >
            {{ disposition.disposition }}
          </button>
        </div>
      </div>
    </ng-container>

    <button
      class="icon opt-out-icon d-flex flex-column align-items-center btn py-3"
      (click)="onTriggerOptOut(data.currentConv)"
      [disabled]="data.currentConv.hasOptedOut"
    >
      <svg class="mb-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          d="M329.5 177.8l-11.3-11.3c-6.2-6.2-16.4-6.2-22.6 0L256 206.1l-39.6-39.6c-6.2-6.2-16.4-6.2-22.6 0l-11.3 11.3c-6.2 6.2-6.2 16.4 0 22.6l39.6 39.6-39.6 39.6c-6.2 6.2-6.2 16.4 0 22.6l11.3 11.3c6.2 6.2 16.4 6.2 22.6 0l39.6-39.6 39.6 39.6c6.2 6.2 16.4 6.2 22.6 0l11.3-11.3c6.2-6.2 6.2-16.4 0-22.6L289.9 240l39.6-39.6c6.3-6.2 6.3-16.4 0-22.6zM256 32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5c-6.6 7-8.4 17.2-4.6 26S14.4 480 24 480c61.5 0 110-25.7 139.1-46.3C192 442.8 223.2 448 256 448c141.4 0 256-93.1 256-208S397.4 32 256 32zm0 368c-26.7 0-53.1-4.1-78.4-12.1l-22.7-7.2-19.5 13.8c-14.3 10.1-33.9 21.4-57.5 29 7.3-12.1 14.4-25.7 19.9-40.2l10.6-28.1-20.6-21.8C69.7 314.1 48 282.2 48 240c0-88.2 93.3-160 208-160s208 71.8 208 160-93.3 160-208 160z"
        />
      </svg>
      {{ data.currentConv.hasOptedOut ? ('convNavbar.optedOut' | translate) : ('convNavbar.optOut' | translate) }}
    </button>
  </div>

  <!------------------------------------------------->
  <ng-template #defaultEndButton>
    <button
      class="icon end-icon d-flex flex-column align-items-center btn py-3"
      (click)="onCompletedConversation(data.currentConv)"
    >
      <svg class="mb-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path
          d="M368 224c-17.62 0-32 14.38-32 32s14.38 32 32 32s32-14.38 32-32S385.6 224 368 224zM552 464H480V64c0-35.2-28.8-64-64-64H160C124.8 0 96 28.8 96 64v400H24C10.75 464 0 474.7 0 488C0 501.3 10.75 512 24 512h528c13.25 0 24-10.75 24-24C576 474.7 565.3 464 552 464zM432 464h-288V64c0-8.838 7.164-16 16-16h256c8.836 0 16 7.162 16 16V464z"
        />
      </svg>
      {{ data.currentConv.isCompleted ? ('convNavbar.ended' | translate) : ('convNavbar.endConversation' | translate) }}
    </button>
  </ng-template></ng-container
>
