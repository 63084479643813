<div class="d-flex flex-column justify-content-center align-items-center h-100 text-center">
  <div
    *ngIf="domain$ | async as domain; else loading"
    id="signup-container"
    class="my-auto d-flex flex-column align-items-center p-3"
  >
    <img class="logo" [src]="domain.logo" />

    <h2 class="header-text account-name">{{ domain.account_name }}</h2>

    <div class="form-section">
      <h4 class="header-text">{{ 'signup.newUser' | translate }}</h4>
      <form class="d-flex flex-column justify-content-center" [formGroup]="form" (ngSubmit)="onSubmit(domain.aid)">
        <div class="mb-3 d-flex justify-content-between w-100">
          <label for="firstname" class="sr-only">
            {{ 'signup.firstName' | translate }}
          </label>

          <input
            class="mr-2 form-control"
            formControlName="first_name"
            id="firstname"
            [placeholder]="'signup.firstName' | translate"
            required
            type="text"
          />

          <label for="lastname " class="sr-only">
            {{ 'signup.lastName' | translate }}
          </label>

          <input
            class="ml-2 form-control"
            formControlName="last_name"
            id="lastname"
            [placeholder]="'signup.lastName' | translate"
            required
            type="text"
          />
        </div>

        <div class="d-flex flex-column mb-4">
          <label for="email" class="sr-only">{{ 'common.email' | translate }}</label>
          <input
            autofocus
            class="mb-3 form-control"
            formControlName="email"
            id="email"
            [placeholder]="'common.email' | translate"
            required
            type="email"
            autocomplete="off"
          />
          <label for="password" class="sr-only">{{ 'common.password' | translate }}</label>
          <input
            class="mb-3 form-control"
            formControlName="password"
            id="password"
            [placeholder]="'common.password' | translate"
            required
            type="password"
            autocomplete="off"
          />
          <label for="password2" class="sr-only">{{ 'signup.confirmPassword' | translate }}</label>
          <input
            class="mb-3 form-control"
            formControlName="password2"
            id="password2"
            [placeholder]="'signup.confirmPassword' | translate"
            required
            type="password"
            autocomplete="off"
          />
        </div>
        <p *ngIf="error" class="mb-3 text-danger">{{ error }}</p>

        <button class="signup-btn btn btn-lg" [disabled]="!form.valid || submitting" type="submit">
          <span *ngIf="!submitting">{{ 'signup.signup' | translate }}</span>
          <app-spinner *ngIf="submitting" width="2rem"></app-spinner>
        </button>

        <p class="mt-3 already-registered-text">
          {{ 'signup.registered' | translate }}
          <a class="log-in-text ml-1" [routerLink]="['../login']">{{ 'signup.login' | translate }}</a>
        </p>
      </form>
    </div>
  </div>

  <ng-template #loading>
    <h2>{{ 'common.loading' | translate }}...</h2>
  </ng-template>

  <app-footer class="bottom-footer"></app-footer>
</div>
