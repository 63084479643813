<div class="action-menu-background" (click)="clickOutside($event)">
  <div class="action-menu shadow" [ngStyle]="{ 'left.px': posX, 'top.px': posY }">
    <p class="font-text mb-0 p-3">Insert as answer for a question</p>

    <div class="list-group list-group-flush">
      <button *ngFor="let q of questions" class="list-group-item list-group-item-action" (click)="onSelect(q)">
        {{ q.title }}
      </button>
    </div>

    <p class="font-text mb-0 p-3">Actions</p>

    <div class="list-group list-group-flush">
      <button
        class="list-group-item list-group-item-action"
        (click)="onEndConv()"
        *ngIf="!eventMenuService?.currentConversation?.isCompleted"
      >
        End Conversation
      </button>
      <button
        class="list-group-item list-group-item-action"
        disabled="true"
        *ngIf="eventMenuService?.currentConversation?.isCompleted"
      >
        Conversation Ended
      </button>

      <button
        class="list-group-item list-group-item-action opt-out"
        (click)="onOptOutConv()"
        *ngIf="!eventMenuService?.currentConversation?.hasOptedOut"
      >
        Opt-Out
      </button>
      <button
        disabled="true"
        class="list-group-item list-group-item-action"
        *ngIf="eventMenuService?.currentConversation?.hasOptedOut"
      >
        Opted-Out
      </button>
    </div>
  </div>
</div>
