<div class="modal-header" ngbAutofocus>
  <h4 class="modal-title">Are you still there?</h4>
</div>
<div class="p-4">
  <h5 class="text-center pb-4 m-0">What is {{ number1 }} X {{ number2 }}?</h5>
  <div class="d-flex justify-content-around">
    <button *ngFor="let i of options" type="button" class="btn btn-primary" (click)="onSubmit(i)">
      {{ i }}
    </button>
  </div>
</div>
