<div class="d-flex flex-column justify-content-center align-items-center h-100 text-center">
  <div
    *ngIf="data$ | async as data; else loading"
    id="main-container"
    class="my-auto d-flex flex-column align-items-center px-3"
  >
    <img class="logo" [src]="data.domain.logo" />

    <h2 class="header-text account-name">{{ data.domain.account_name }}</h2>

    <div class="form-section">
      <h4 class="header-text">
        {{ isWelcome ? ('reset.selectPassword' | translate) : ('reset.newPassword' | translate) }}
      </h4>

      <form
        class="d-flex flex-column align-content-center"
        [formGroup]="form"
        (ngSubmit)="onSubmit(data.code, data.email)"
      >
        <label for="password" class="sr-only">
          {{ 'common.password' | translate }}
        </label>
        <input
          autofocus
          class="mb-3 form-control"
          formControlName="password"
          id="password"
          [placeholder]="'common.password' | translate"
          required
          type="password"
        />

        <label for="confirm" class="sr-only">
          {{ 'reset.confirm' | translate }}
        </label>
        <input
          class="form-control"
          formControlName="confirm"
          id="confirm"
          [placeholder]="'reset.confirm' | translate"
          required
          type="password"
        />

        <p *ngIf="message" class="mt-3 text-{{ messageType || 'danger' }}">
          {{ message | translate }}
        </p>

        <button class="submit-btn btn btn-lg w-100" [disabled]="!form.valid || submitting" type="submit">
          <span *ngIf="!submitting">{{ 'reset.setPassword' | translate }}</span>
          <app-spinner *ngIf="submitting" width="2rem"></app-spinner>
        </button>

        <p class="mt-3 already-registered-text">
          {{ 'signup.registered' | translate }}
          <a class="log-in-text ml-1" [routerLink]="['../login']">{{ 'signup.login' | translate }}</a>
        </p>
      </form>
    </div>
  </div>

  <ng-template #loading>
    <h2 class="my-auto">{{ 'common.loading' | translate }}...</h2>
  </ng-template>

  <app-footer bgColor="gray-800" textColor="gray-690"></app-footer>
</div>
