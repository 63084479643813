<ng-container *ngIf="data$ | async as data">
  <div *ngIf="lead?.isOutsideHours" class="after-hours d-flex justify-content-end pb-2 ">
    <img class="mr-2" src="assets/svgs/alarm-exclamation.svg" [alt]="'actionsFooter.alarmClock' | translate" />
    {{
      'actionsFooter.localTime'
        | translate
          : {
              time: lead.localtime | date: 'shortTime',
              name: lead.first_name || 'this contact'
            }
    }}
  </div>

  <ng-container *ngIf="redactedService.shouldRedact">
    <div class="message-input w-100 d-flex align-items-center">
      <textarea
        #textarea
        id="redacted-textarea"
        readonly
        class="w-100 rounded"
        [placeholder]="canvasserPlaceholderText"
        (focus)="onTextareaFocus()"
        (blur)="onTextareaBlur()"
      ></textarea>
    </div>
  </ng-container>

  <!--Message box------------------------------------------------------------------>
  <div
    #inputRow
    class="message-input w-100 align-items-center"
    [ngClass]="{ 'd-none': redactedService.shouldRedact, 'd-flex': !redactedService.shouldRedact }"
  >
    <div *ngIf="templateMedia?.thumbnail_url" id="media-container" class="">
      <img #media class="rounded" id="media-thumbnail" [src]="templateMedia?.thumbnail_url" (click)="previewMedia()" />

      <svg
        *ngIf="data.canEditMessages"
        (click)="clearMedia()"
        class="remove-btn"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z"
        />
      </svg>
    </div>

    <div *ngIf="templateMedia" class="top-line align-items-center"></div>

    <textarea
      #textarea
      autocomplete="off"
      [readonly]="!data.canEditMessages || aiSuggestionText"
      [formControl]="text"
      id="message-textarea "
      class="w-100 rounded"
      [placeholder]="'actionsFooter.messageText' | translate"
      (focus)="onTextareaFocus()"
      (blur)="onTextareaBlur()"
    ></textarea>

    <div class="bottom-buttons">
      <button
        class="btn btn-primary"
        id="send-btn"
        [disabled]="!data.canSendMessages || !text.valid"
        (click)="sendButtonClicked.next('send')"
        [class.template-media]="templateMedia"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M511.1 255.1c0 12.8-7.625 24.38-19.41 29.41L44.6 477.4c-4.062 1.75-8.344 2.594-12.59 2.594c-8.625 0-17.09-3.5-23.28-10.05c-9.219-9.766-11.34-24.25-5.344-36.27l73.66-147.3l242.1-30.37L77.03 225.6l-73.66-147.3C-2.623 66.3-.4982 51.81 8.72 42.05c9.25-9.766 23.56-12.75 35.87-7.453L492.6 226.6C504.4 231.6 511.1 243.2 511.1 255.1z"
          />
        </svg>
      </button>

      <svg
        class="d-none d-md-block"
        id="emoji-picker"
        (click)="openEmojiPicker()"
        [class.template-media]="templateMedia"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          d="M256 352C293.2 352 319.2 334.5 334.4 318.1C343.3 308.4 358.5 307.7 368.3 316.7C378 325.7 378.6 340.9 369.6 350.6C347.7 374.5 309.7 400 256 400C202.3 400 164.3 374.5 142.4 350.6C133.4 340.9 133.1 325.7 143.7 316.7C153.5 307.7 168.7 308.4 177.6 318.1C192.8 334.5 218.8 352 256 352zM208.4 208C208.4 225.7 194 240 176.4 240C158.7 240 144.4 225.7 144.4 208C144.4 190.3 158.7 176 176.4 176C194 176 208.4 190.3 208.4 208zM304.4 208C304.4 190.3 318.7 176 336.4 176C354 176 368.4 190.3 368.4 208C368.4 225.7 354 240 336.4 240C318.7 240 304.4 225.7 304.4 208zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"
        />
      </svg>
    </div>
  </div>

  <app-emoji-picker
    class="d-none d-md-block"
    *ngIf="emojiPickerIsOpen"
    (close)="emojiPickerIsOpen = false"
    (selected)="onEmojiSelected($event)"
  >
  </app-emoji-picker>

  <button
    id="send-and-new-btn"
    class="btn btn-lg btn-primary mt-2 w-100"
    [disabled]="aiSuggestionText || testJobsService.isTestJob || !data.canSendMessages"
    (click)="sendButtonClicked.next('sendAndNew')"
    [ngSwitch]="sendButtonText"
  >
    <svg *ngIf="text.value" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path
        d="M511.1 255.1c0 12.8-7.625 24.38-19.41 29.41L44.6 477.4c-4.062 1.75-8.344 2.594-12.59 2.594c-8.625 0-17.09-3.5-23.28-10.05c-9.219-9.766-11.34-24.25-5.344-36.27l73.66-147.3l242.1-30.37L77.03 225.6l-73.66-147.3C-2.623 66.3-.4982 51.81 8.72 42.05c9.25-9.766 23.56-12.75 35.87-7.453L492.6 226.6C504.4 231.6 511.1 243.2 511.1 255.1z"
      />
    </svg>
    <svg *ngIf="!text.value" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path
        d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"
      />
    </svg>
    <ng-container *ngSwitchCase="'sendAndNew'">
      {{ 'actionsFooter.sendAndNew' | translate }}
    </ng-container>
    <ng-container *ngSwitchCase="'sendAndNext'">
      {{ 'actionsFooter.sendAndNext' | translate }}
    </ng-container>
    <ng-container *ngSwitchCase="'new'">
      {{ 'actionsFooter.new' | translate }}
    </ng-container>
    <ng-container *ngSwitchDefault>
      {{ 'actionsFooter.next' | translate }}
    </ng-container>
  </button>

  <ng-container *ngIf="(jobsService.currentJobAiEnabled$ | async) && aiSuggestionText">
    <div id="ai-suggestion">
      <h4>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M324.42 103.15L384 128l24.84 59.58a8 8 0 0 0 14.32 0L448 128l59.58-24.85a8 8 0 0 0 0-14.31L448 64 423.16 4.42a8 8 0 0 0-14.32 0L384 64l-59.58 24.84a8 8 0 0 0 0 14.31zm183.16 305.69L448 384l-24.84-59.58a8 8 0 0 0-14.32 0L384 384l-59.58 24.84a8 8 0 0 0 0 14.32L384 448l24.84 59.58a8 8 0 0 0 14.32 0L448 448l59.58-24.84a8 8 0 0 0 0-14.32zM384 255.64a16.06 16.06 0 0 0-8.84-14.33l-112.57-56.39-56.28-112.77c-5.44-10.87-23.19-10.87-28.62 0l-56.28 112.77L8.84 241.31a16 16 0 0 0 0 28.67l112.57 56.39 56.28 112.77a16 16 0 0 0 28.62 0l56.28-112.77L375.16 270a16.07 16.07 0 0 0 8.84-14.36z"
          />
        </svg>
        <span>{{ aiSuggestionText.header | translate }}</span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M324.42 103.15L384 128l24.84 59.58a8 8 0 0 0 14.32 0L448 128l59.58-24.85a8 8 0 0 0 0-14.31L448 64 423.16 4.42a8 8 0 0 0-14.32 0L384 64l-59.58 24.84a8 8 0 0 0 0 14.31zm183.16 305.69L448 384l-24.84-59.58a8 8 0 0 0-14.32 0L384 384l-59.58 24.84a8 8 0 0 0 0 14.32L384 448l24.84 59.58a8 8 0 0 0 14.32 0L448 448l59.58-24.84a8 8 0 0 0 0-14.32zM384 255.64a16.06 16.06 0 0 0-8.84-14.33l-112.57-56.39-56.28-112.77c-5.44-10.87-23.19-10.87-28.62 0l-56.28 112.77L8.84 241.31a16 16 0 0 0 0 28.67l112.57 56.39 56.28 112.77a16 16 0 0 0 28.62 0l56.28-112.77L375.16 270a16.07 16.07 0 0 0 8.84-14.36z"
          />
        </svg>
      </h4>
      <div
        [innerHTML]="aiSuggestionText.body | translate: aiSuggestionText.bodyparams"
        class="ai-suggestion-body"
      ></div>
      <div>{{ aiSuggestionText.footer | translate }}</div>
      <div class="align-self-end mt-3">
        <button type="button" class="btn btn-secondary" (click)="onDismissAiSuggestion()">
          {{ 'common.dismiss' | translate }}
        </button>
        <button type="button" class="ml-3 btn btn-primary" (click)="onAcceptAiSuggestion()">
          {{ aiSuggestionText.acceptButtonText | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>
