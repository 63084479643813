import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Agent, AgentShiftStatus } from '../models/agent';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalAgentService {
  currentAgent: Agent;

  constructor(private authService: AuthService, private router: Router) {
    this.routeGlobalAgentsOnTeamChange();
  }

  routeGlobalAgentsOnTeamChange() {
    this.authService.agent$.pipe(filter(agent => !!agent && agent.aid === 'GLOBAL')).subscribe(agent => {
      console.log('New Global Agent', agent);
      if (!agent.team_id) {
        // Agent is not on a shift
        this.router.navigateByUrl('accounts/GLOBAL/awaiting-team');
      } else if (
        agent.shift?.status === AgentShiftStatus.Break &&
        (!this.currentAgent || !this.currentAgent.shift || this.currentAgent?.shift?.status !== AgentShiftStatus.Break)
      ) {
        // Agent is going on break
        this.router.navigateByUrl('accounts/GLOBAL/break');
      } else if (
        (agent && !this.currentAgent) ||
        agent.team_id !== this.currentAgent?.team_id ||
        (agent.shift?.status !== AgentShiftStatus.Break && this.currentAgent?.shift?.status === AgentShiftStatus.Break)
      ) {
        // Agent is coming off break or changing teams
        this.router.navigateByUrl('accounts/GLOBAL/jobs/default');
      }

      this.currentAgent = agent;
    });
  }
}
