<div class="conv d-flex flex-column flex-xl-row flex-grow-1 overflow-hidden w-100 my-auto">
  <ng-container *ngIf="data$ | async as data; else convLoading">
    <!-- New header for mobile Conversation views------------------->
    <div class="top-header d-flex d-xl-none">
      <!--Left side Back Button-->
      <button class="back-btn btn btn-link d-flex d-lg-none align-items-center" (click)="onBack()">
        <svg class="back-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <path
            d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
          />
        </svg>
        <span class="ml-1">Back</span>
      </button>

      <!--Right side User Information---------------------------->
      <button
        class="right-btn btn btn-link d-flex justify-content-between align-items-center pl-4 w-100"
        (click)="onShowDetails()"
      >
        <div *ngIf="lead$ | async as lead" class="contact-info d-flex flex-column align-items-start">
          <ng-container *ngIf="redactedService.shouldRedact; else normalContactInfo">
            <h3 class="contact-name">{{ lead.lead_phone | phone }}</h3>
          </ng-container>
          <ng-template #normalContactInfo>
            <h2 class="contact-name" id="firstName">{{ lead.first_name }} {{ lead.last_name }}</h2>
            <h3 class="contact-phone" id="phoneNumber  ">
              {{ lead.lead_phone | phone }}
            </h3>
          </ng-template>
        </div>

        <!-- Right Chevron-->
        <svg
          *ngIf="!showDetails"
          class="detail-icon align-items-center justify-content-center mr-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
        >
          <path
            d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
          />
        </svg>
      </button>
    </div>

    <!--Right Side Bar Footer Section------------------------------>
    <div class="conv-main-area d-flex flex-column position-relative overflow-hidden">
      <ng-container *ngIf="redactedService.shouldRedact; else normalConvBody">
        <div class="redacted-conv-body flex-grow-1 d-flex align-items-center justify-content-center p-2">
          <app-transmission-rate-indicator></app-transmission-rate-indicator>
          <h2>Voter Text Message Reminder - Content approved by the campaign</h2>
        </div>
      </ng-container>
      <ng-template #normalConvBody>
        <app-conv-body
          #convBody
          class="flex-grow-1 p-2"
          [events]="data.events"
          (event)="onEvent($event)"
        ></app-conv-body>
      </ng-template>

      <app-conv-actions-footer
        #actionsFooter
        class="p-3"
        (event)="onEvent($event)"
        [conversation]="data.conv"
        [aiSuggestion]="data.aiSuggestion"
        [sendButtonStatus]="data.sendButtonStatus"
      ></app-conv-actions-footer>

      <!--Ended and Opted Out buttons - Mobile Screen------------------------------>
      <div *ngIf="isMediumScreenWidth && showDetails" class="mobile-conv-sidebar d-flex flex-column flex-column ">
        <app-conv-sidebar
          class="side-bar-mobile flex-grow-1 d-flex flex-column p-4"
          (templateSelected)="onTemplateSelection($event)"
          [hasLeadResponse]="data.hasLeadResponse"
          [conversation]="data.conv"
        >
        </app-conv-sidebar>

        <app-conv-actions class="bottom-btn" (event)="onEvent($event)"> </app-conv-actions>
      </div>
    </div>

    <!--Ended and Opted Out buttons - Normal Screen------------------------------>
    <div class="normal-conv-sidebar d-none d-xl-flex flex-xl-column justify-content-between">
      <app-conv-sidebar
        class="flex-grow-1 px-3 d-flex flex-column"
        (templateSelected)="onTemplateSelection($event)"
        [hasLeadResponse]="data.hasLeadResponse"
        [conversation]="data.conv"
      >
      </app-conv-sidebar>

      <app-conv-actions class="bottom-btn" (event)="onEvent($event)"> </app-conv-actions>
    </div>

    <swal
      #confirmSendSwal
      title="{{ data.confirm.title | translate }}"
      text="{{ data.confirm.text | translate: data.confirm.params }}"
      [showCancelButton]="true"
      cancelButtonText="{{ 'common.cancel' | translate }}"
      [focusCancel]="true"
      confirmButtonText="{{ 'conv.sendAnyway' | translate }}"
      [reverseButtons]="true"
    ></swal>

    <app-event-action-menu
      *ngIf="eventMenu$ | async as eventMenu"
      [event]="eventMenu.context.event"
      [posX]="eventMenu.context.posX"
      [posY]="eventMenu.context.posY"
      [questions]="eventMenu.questions"
    >
    </app-event-action-menu>
  </ng-container>

  <ng-template #convLoading>
    <div class="d-flex mx-auto my-auto">
      <app-spinner width="4rem" class="spinner"></app-spinner>
    </div>
  </ng-template>
</div>
