import { Component, OnInit } from '@angular/core';
import { SignoutService } from '../services/signout.service';
import { SkillsCheckService } from '../services/skills-check.service';
import { AuthService } from '../services/auth.service';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-no-team-agent-page',
  templateUrl: './no-team-agent-page.component.html',
  styleUrls: ['./no-team-agent-page.component.scss']
})
export class NoTeamAgentPageComponent implements OnInit {
  supplementalCanvasser = this.authService.agent$.pipe(map(agent => !!agent.primary_canvasser_id));

  constructor(
    private authService: AuthService,
    private signoutService: SignoutService,
    private skillsCheckService: SkillsCheckService
  ) {}

  ngOnInit(): void {
    this.skillsCheckService.startSkillsCheckMonitorForGlobalAgents();
  }

  signOut() {
    this.signoutService.signout();
  }
}
