<div *ngIf="agent$ | async" class="d-flex position-relative flex-grow-1 mh-100">
  <!--Alert Message-------------------------------------->
  <div id="alert-area">
    <div
      class="alert"
      *ngFor="let alert of alertsService.messages"
      [ngClass]="'alert-' + (alert.alert_type || 'danger')"
      (click)="alertsService.clearMessage(alert.id)"
    >
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span class="sr-only">{{ 'common.close' | translate }}</span>
        <span aria-hidden="true">&times;</span>
      </button>

      {{ alert.text | translate: alert.params }}
    </div>
  </div>

  <app-sidebar class="d-flex flex-column rightBorder" [class.hide]="isSmallScreenWidth && (sidebarIsClosed$ | async)">
  </app-sidebar>

  <main class="flex-grow-1 d-flex flex-column">
    <div
      class="alert mb-0 rounded-0"
      *ngIf="alertsService.systemMessage.text"
      [ngClass]="'alert-' + (alertsService.systemMessage.alert_type || 'danger')"
    >
      {{ alertsService.systemMessage.text }}
    </div>

    <div
      *ngIf="testJobsService.isTestJob"
      class="alert mb-0 rounded-0 alert-info"
      [innerHTML]="'alert.testMode' | translate"
    ></div>

    <router-outlet></router-outlet>
  </main>

  <div *ngIf="spinnerService.isSpinning" class="spinner position-fixed">
    <div class="background d-flex flex-column align-items-center justify-content-center position-fixed"></div>

    <div class="iconText d-flex flex-column align-items-center justify-content-center position-absolute mt-2">
      <h2 class="text-center text">
        {{ spinnerService.spinnerText | translate }}
      </h2>
      <svg width="135" height="140" viewBox="0 0 135 140" xmlns="http://www.w3.org/2000/svg">
        <rect y="10" width="15" height="120" rx="6">
          <animate
            attributeName="height"
            begin="0.5s"
            dur="1s"
            values="120;110;100;90;80;70;60;50;40;140;120"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            begin="0.5s"
            dur="1s"
            values="10;15;20;25;30;35;40;45;50;0;10"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="30" y="10" width="15" height="120" rx="6">
          <animate
            attributeName="height"
            begin="0.25s"
            dur="1s"
            values="120;110;100;90;80;70;60;50;40;140;120"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            begin="0.25s"
            dur="1s"
            values="10;15;20;25;30;35;40;45;50;0;10"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="60" width="15" height="140" rx="6">
          <animate
            attributeName="height"
            begin="0s"
            dur="1s"
            values="120;110;100;90;80;70;60;50;40;140;120"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            begin="0s"
            dur="1s"
            values="10;15;20;25;30;35;40;45;50;0;10"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="90" y="10" width="15" height="120" rx="6">
          <animate
            attributeName="height"
            begin="0.25s"
            dur="1s"
            values="120;110;100;90;80;70;60;50;40;140;120"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            begin="0.25s"
            dur="1s"
            values="10;15;20;25;30;35;40;45;50;0;10"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="120" y="10" width="15" height="120" rx="6">
          <animate
            attributeName="height"
            begin="0.5s"
            dur="1s"
            values="120;110;100;90;80;70;60;50;40;140;120"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            begin="0.5s"
            dur="1s"
            values="10;15;20;25;30;35;40;45;50;0;10"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </rect>
      </svg>
    </div>
  </div>
</div>
