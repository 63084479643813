<div *ngIf="agent$ | async as agent" class="d-flex flex-column details-column">
  <!--Contact Info------------------------------------------------------------->
  <p class="section-header">
    {{ 'convLead.contactInfo' | translate }}
  </p>

  <div *ngIf="data.lead; else nocontactinfo" class="section-info">
    <div class="info-item">
      {{ data.lead.title }} {{ data.lead.first_name }} {{ data.lead.last_name }}
      {{ data.lead.suffix }}
    </div>

    <ng-container *ngIf="!agent.isGlobalAgent">
      <div class="info-item">{{ data.lead.address1 }}</div>
      <div class="info-item">{{ data.lead.address2 }}</div>
      <div class="info-item">
        {{ data.lead.city }}<span *ngIf="data.lead.city && data.lead.state">,</span> {{ data.lead.state }}
        {{ data.lead.zip }}
      </div>
      <div class="info-item">{{ data.lead.email }}</div>
      <div *ngIf="data.lead.localtime" class="info-item">
        {{ 'convLead.localtime' | translate }}:
        {{ data.lead.localtime | date: 'shortTime' }}
      </div>
    </ng-container>
  </div>

  <ng-template #nocontactinfo>
    <div>{{ 'convLead.noContact' | translate }}</div>
  </ng-template>

  <!--Extra Info-------------------------------------------------------------->
  <ng-container *ngIf="!agent.isGlobalAgent">
    <p class="section-header mt-4">
      {{ 'convLead.extra' | translate }}
    </p>

    <ul *ngIf="data.auxData && data.auxData.length > 0; else noextrainfo" class="section-info list-unstyled">
      <li *ngFor="let item of data.auxData; let first = first" class="d-flex align-items-center mt-2">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="far"
          data-icon="angle-double-right"
          class="text-muted flex-shrink-0"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
        >
          <path
            fill="currentColor"
            d="M363.8 264.5L217 412.5c-4.7 4.7-12.3 4.7-17 0l-19.8-19.8c-4.7-4.7-4.7-12.3 0-17L298.7 256 180.2 136.3c-4.7-4.7-4.7-12.3 0-17L200 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17zm-160-17L57 99.5c-4.7-4.7-12.3-4.7-17 0l-19.8 19.8c-4.7 4.7-4.7 12.3 0 17L138.7 256 20.2 375.7c-4.7 4.7-4.7 12.3 0 17L40 412.5c4.7 4.7 12.3 4.7 17 0l146.8-148c4.7-4.7 4.7-12.3 0-17z"
          ></path>
        </svg>

        <span class="info-item ml-2"> {{ item }}</span>
      </li>
    </ul>

    <ng-template #noextrainfo>
      <div class="section-info">{{ 'convLead.noExtra' | translate }}</div>
    </ng-template>
  </ng-container>

  <div *ngIf="conversation.ai_scores && showAiScores" class="ai-container mt-4 p-3">
    <!--AI Scores sections--------------------------->
    <div class="ai-score-section">
      <div class="ai-score-title mb-1">
        AI Score:
      </div>

      <div class="ai-score-info">
        <div>Engagement:</div>
        <div class="ai-score">{{ conversation.ai_scores.engagement }}</div>
      </div>

      <div class="ai-score-info">
        <div>Wrong Number:</div>
        <div class="ai-score">{{ conversation.ai_scores.wrong_number }}</div>
      </div>

      <div class="ai-score-info">
        <div>Opt Out:</div>
        <div class="ai-score">{{ conversation.ai_scores.opt_out }}</div>
      </div>

      <div class="ai-score-info">
        <div>Support:</div>
        <div class="ai-score">{{ conversation.ai_scores.support }}</div>
      </div>
    </div>

    <!--AI Tags sections--------------------------->
    <div class="ai-tags-title mt-3 mb-1">
      AI Tags:
    </div>

    <div class="ai-tags">
      <div *ngFor="let tag of conversation.ai_tags" class="ai-tag mr-1 p-2">
        {{ tag }}
      </div>
    </div>
  </div>
</div>
